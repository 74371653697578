import React from "react";
import { Box, Grid, IconButton, Menu, MenuItem, styled, Typography } from "@mui/material";
import FloralBg from "../../resources/images/svg/FloralPage@1,5x.svg";
import logo from "../../resources/images/svg/logo.svg";
import userLogo from "../../resources/images/svg/user.svg";
import { useIsMobile } from "../../hooks/responsive";
import { getLoginRedirectURL, useIsLoggedIn } from "../../hooks/auth";
import SuccessfulConnexionSnackbar from "./SuccessfulConnexionSnackbar";
import { useSelector } from "react-redux";
import { getUser } from "../../store/reducers/authSlice";
import burgerMenu from "../../resources/images/svg/burger_menu.svg";
import MobileMenu from "./MobileMenu";
import { useGetUniversQuery } from "../../store/api/app";
import Universe from "./Universe";

const Container = styled(Grid)(({ theme }) => {
	return {
		width: "100%",
		minHeight: "250px",
		position: "relative",
		display: "flex",
		zIndex: theme.zIndex.appBar,
	};
});

export const BackgroundBox = styled(Box)((theme) => {
	return {
		position: "absolute",
		top: 0,
		left: 0,
		width: "100%",
		height: "100%",
		background: "linear-gradient(90deg, #162941 0%, #8F6C9F 100%)",
		overflow: "hidden",
	};
});

export const GradientTypo = styled(Typography)(({ theme, disabled = false, fontSize = "40px" }) => {
	return {
		fontWeight: 700,
		background:
			disabled === false
				? "linear-gradient(90deg, #EE7992 0%, #F1AE65 50.51%, #F4C54D 100%)"
				: theme.palette.grey["400"],
		WebkitBackgroundClip: "text",
		WebkitTextFillColor: "transparent",
		overflow: "hidden",
		fontFamily: "Branding Bold",
		fontSize: fontSize,
		display: "block",
		"&:first-letter": {
			textTransform: "capitalize !important",
		},
	};
});

export const Subtitle = styled(Typography)((theme) => {
	return {
		textAlign: "left",
		color: "white",
		fontFamily: "Branding SemiBold",
		fontWeight: 600,
		fontSize: 20,
		maxWidth: 700,
	};
});

export const UserInitialsContainer = styled(Box)(({ theme }) => {
	return {
		backgroundColor: "rgba(36,38,56,0.53)",
		borderRadius: "50%",
		height: 42,
		width: 42,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		transition: theme.transitions.create(["box-shadow"]),
		"&:hover": {
			boxShadow: "0 0 15px 0 rgb(36 38 56 / 53%)",
		},
	};
});

const Header = () => {
	const isMobile = useIsMobile();
	const user = useSelector(getUser);

	const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false);

	const isLoggedIn = useIsLoggedIn();

	const redirectUrl = getLoginRedirectURL();

	return (
		<>
			<MobileMenu isOpen={mobileMenuOpen} close={() => setMobileMenuOpen(false)} />
			<Container container px={isMobile ? 2 : 5} py={5}>
				<Grid container direction={"column"}>
					<Grid container direction={"row"} justifyContent={"space-between"} marginBottom={isMobile ? 3 : 5}>
						<Grid item justifyContent={"flex-start"} alignItems={"center"} display={"flex"}>
							<img src={logo} className="" alt="logo" style={{}} />
						</Grid>
						{!isMobile ? (
							<Grid item display={"flex"} alignItems={"center"} gap={4}>
								<Universe />
								{!isLoggedIn ? (
									<a href={redirectUrl}>
										<IconButton sx={{ width: 40, height: 40 }} size={"medium"}>
											<img src={userLogo} alt={"user-logo"} />
										</IconButton>
									</a>
								) : (
									<UserInitialsContainer>
										{!!user && (
											<Typography color={"white"} fontFamily={"Branding Bold"}>
												{`${user?.prenom[0]?.toUpperCase()}${user?.nom[0]?.toUpperCase()}`}
											</Typography>
										)}
									</UserInitialsContainer>
								)}
							</Grid>
						) : (
							<IconButton sx={{ bottom: "6px" }} onClick={() => setMobileMenuOpen(true)}>
								<img style={{ width: "18px" }} src={burgerMenu} alt={"burger-menu"} />
							</IconButton>
						)}
					</Grid>
					<Grid item container direction={"column"} px={isMobile ? 0 : 10}>
						<Grid item style={{ marginRight: "auto", maxWidth: "100%" }}>
							<GradientTypo variant={"h4"} textAlign={"left"}>
								Bienvenue {user?.prenom?.capitalize()}
							</GradientTypo>
						</Grid>
						<Grid item style={{ marginRight: "auto" }}>
							<Subtitle variant={"h5"}>Vous êtes ici dans votre espace personnel...</Subtitle>
						</Grid>
					</Grid>
				</Grid>
				<SuccessfulConnexionSnackbar />
			</Container>
		</>
	);
};

export default Header;
