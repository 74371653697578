import React from "react";
import { Box, FormControl, Grid, Input, InputAdornment, styled, Typography, useTheme } from "@mui/material";
import { SubTitle } from "../../routes/Abonnement";
import SearchIcon from "@mui/icons-material/Search";
import InexCard from "../InexCard";
import EastIcon from "@mui/icons-material/East";
import { useIsMobile } from "../../hooks/responsive";
import { useSearchParams } from "react-router-dom";

const FaqCategories = ({ categories }) => {
	const isMobile = useIsMobile();
	const theme = useTheme();
	let [searchParams, setSearchParams] = useSearchParams();

	return (
		<Box>
			<Grid item xs={12}>
				<SubTitle>À propos de quel sujet pouvons-nous vous apporter une réponse ?</SubTitle>
			</Grid>
			<Grid container spacing={3} sx={{ marginTop: 1 }}>
				{categories?.map((item) => (
					<Grid item md={6} xs={12} key={item.idcat}>
						<InexCard
							sx={{
								display: "flex",
								flexDirection: "row",
								padding: (theme) => theme.spacing(isMobile ? 1 : 2),
								paddingTop: theme.spacing(isMobile ? 1 : 2),
								paddingBottom: theme.spacing(isMobile ? 1 : 2),
								alignItems: "center",
								borderRadius: "8px !important",
								boxShadow: "box-shadow: 0 2px 29px 0 rgba(22,42,65,0.2)",
								justifyContent: "space-between",
							}}
							onClick={() => {
								setSearchParams({ categorie: item.idcat });
							}}
						>
							<Box sx={{ display: "flex", alignItems: "center" }}>
								<img src={item.image} alt={item.titre} />
								<Typography
									marginLeft={2}
									fontFamily="Branding Medium"
									fontWeight={"600"}
									dangerouslySetInnerHTML={{ __html: item.titre }}
								></Typography>
							</Box>
							<EastIcon />
						</InexCard>
					</Grid>
				))}
			</Grid>
		</Box>
	);
};

export default FaqCategories;
