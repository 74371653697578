import React from "react";
import { Document, Page, View, Text, Link, Font, StyleSheet, Image } from "@react-pdf/renderer";

import logo from "../../resources/images/svg/logo-facture.png";
import { mainStyles } from "./Invoice";
import moment from "moment/moment";
import { formatText } from "./InvoiceContent";

const styles = StyleSheet.create({
	container: {
		width: "100%",
		display: "flex",
		flexDirection: "row",
	},
	subContainer: {
		flexGrow: 1,
		display: "flex",
	},
	image: {
		width: "150px",
	},
	textContainer: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-end",
		alignItems: "center",
		width: "165px",
	},
});

const InvoiceHeader = ({ order, address, ...props }) => {
	var dateString = moment.unix(order.datePaid ? order.datePaid : order.creation).format("DD/MM/YYYY");

	return (
		<View style={styles.container}>
			<View style={styles.subContainer}>
				<Image src={logo} style={styles.image} />
				<Text style={{ ...mainStyles.text, ...mainStyles.textBold }}>INREES</Text>
				<Text style={{ ...mainStyles.text }}>67 rue Saint-Jacques</Text>
				<Text style={{ ...mainStyles.text }}>75005 PARIS</Text>
				<Text style={{ ...mainStyles.text }}>FRANCE</Text>
				<Text style={{ ...mainStyles.text, marginTop: 5 }}>
					Email : <Link src={"mailto:servclient@inrees.com"}>servclient@inrees.com</Link>
				</Text>
				<Text style={{ ...mainStyles.text }}>
					Site : <Link src={"https://www.inexplore.com"}>www.inexplore.com</Link>
				</Text>
			</View>
			<View style={{ ...styles.subContainer, alignItems: "flex-end" }}>
				<Text
					style={{
						...mainStyles.text,
						...mainStyles.textBold,
						...mainStyles.textBig,
						...mainStyles.textLight,
						textAlign: "right",
						textTransform: "uppercase",
						marginTop: 10,
					}}
				>
					{`Facture :   ${formatText(order?.reference)}`}
				</Text>
				<View style={{ ...styles.textContainer, marginTop: 10, justifyContent: "space-between" }}>
					<Text style={{ ...mainStyles.text, ...mainStyles.textSemiBold }}>{"Date :"}</Text>
					<Text style={{ ...mainStyles.text }}>{dateString}</Text>
				</View>
				<View style={{ ...styles.textContainer, justifyContent: "space-between" }}>
					<Text style={{ ...mainStyles.text, ...mainStyles.textSemiBold }}>{"Paiement :"}</Text>
					<Text style={{ ...mainStyles.text }}>{ order.typepaiement === 1 ? "Chèque" : order.typepaiement === 3 ? "Prélèvement SEPA" : "Carte bancaire" }</Text>
				</View>

				<View style={{ ...styles.textContainer, marginTop: 20 }}>
					<Text style={{ ...mainStyles.text, ...mainStyles.textSemiBold, textAlign: "right" }}>
						{`${formatText(address.prenom)}  ${formatText(address.nom)}`}
					</Text>
				</View>
				<View style={{ ...styles.textContainer }}>
					<Text style={{ ...mainStyles.text, textAlign: "right" }}>{formatText(address.address)}</Text>
				</View>
				{address.complement1 && (
					<View style={{ ...styles.textContainer }}>
						<Text style={{ ...mainStyles.text, textAlign: "right" }}>{formatText(address.complement1)}</Text>
					</View>
				)}
				{address.complement2 && (
					<View style={{ ...styles.textContainer }}>
						<Text style={{ ...mainStyles.text, textAlign: "right" }}>{formatText(address.complement2)}</Text>
					</View>
				)}
				<View style={{ ...styles.textContainer }}>
					<Text style={{ ...mainStyles.text, textAlign: "right" }}>{`${address.cp} ${formatText(address.ville)}`}</Text>
				</View>
				<View style={{ ...styles.textContainer }}>
					<Text style={{ ...mainStyles.text, textAlign: "right" }}>{formatText(address.pays)}</Text>
				</View>
			</View>
		</View>
	);
};

export default InvoiceHeader;
