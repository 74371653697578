import React from "react";
import InexCard from "../InexCard";
import { Box, IconButton, Typography } from "@mui/material";
import { decodeString } from "../../hooks/decodeString";
import { TopBorder } from "../abonnement/home/modalEdition/AbonnementPaiementList";

import editIcon from "../../resources/images/svg/mon-profil/edit_3@1,5x.svg";
import deleteIcon from "../../resources/images/svg/mon-profil/trash_2@1,5x.svg";
import { GradientTypo } from "../header/Header";
import { RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material";

const AddressCard = ({
	address,
	selected = false,
	isSubAddress = undefined,
	onClick,
	onDelete,
	onEdit,
	canDelete = true,
	canEdit = true,
	showMessage = true,
	selectable = false,
	...props
}) => {
	if (!address) return null;

	const addressStr = decodeString(address.address);
	const country = decodeString(address.pays);
	const city = decodeString(address.ville);

	const complement1 = decodeString(address.complement1);
	const complement2 = decodeString(address.complement2);

	return (
		<InexCard
			{...props}
			selected={selected}
			onClick={onClick}
			sx={{
				padding: 0,
				paddingTop: 0,
				paddingBottom: 0,
				height: "100%",
				boxShadow: "none",
				borderRadius: (theme) => theme.spacing(1),
				border: (theme) => `1px solid ${theme.palette.grey[300]}`,
				...props.sx,
			}}
		>
			<TopBorder selected={selected} />
			<Box
				py={2.5}
				px={2.5}
				sx={{
					width: "100%",
					height: "100%",
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
				}}
			>
				<Box
					pt={1.5}
					sx={{
						display: "flex",
						flexDirection: "row",
						flexWrap: "nowrap",
						alignItems: "center",
					}}
				>
					<Typography
						variant={"h4"}
						textAlign={"left"}
						color={"secondary"}
						sx={{
							fontFamily: "Branding SemiBold",
							fontSize: 20,
							overflow: "hidden",
							flexGrow: 1,
						}}
					>
						{`${address.prenom} ${address.nom}`}
					</Typography>
					<Box
						sx={{
							marginLeft: (theme) => theme.spacing(2),
							display: "flex",
							flexDirection: "row",
							flexWrap: "nowrap",
						}}
					>
						{onEdit && (
							<IconButton
								size={"large"}
								sx={{
									width: 42,
									height: 42,
								}}
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									onEdit();
								}}
							>
								<img src={editIcon} alt={"editIcon"} />
							</IconButton>
						)}
						{onDelete && !selected && canDelete && (
							<IconButton
								size={"large"}
								sx={{
									width: 42,
									height: 42,
								}}
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									onDelete();
								}}
							>
								<img src={deleteIcon} alt={"deleteIcon"} />
							</IconButton>
						)}
						{selectable && selected && <RadioButtonChecked />}
						{selectable && !selected && <RadioButtonUnchecked />}
					</Box>
				</Box>
				<Box pb={2}>
					<Typography
						textAlign={"left"}
						color={"secondary"}
						noWrap
						sx={{
							fontFamily: "Branding Medium",
							fontSize: 16,
							overflow: "hidden",
						}}
					>
						{`${addressStr}`}
					</Typography>
					{address.complement1 && address.complement1 !== "" && (
						<Typography
							textAlign={"left"}
							color={"secondary"}
							sx={{
								fontFamily: "Branding Medium",
								fontSize: 16,
							}}
						>
							{`${complement1}`}
						</Typography>
					)}
					{address.complement2 && address.complement2 !== "" && (
						<Typography
							textAlign={"left"}
							color={"secondary"}
							sx={{
								fontFamily: "Branding Medium",
								fontSize: 16,
							}}
						>
							{`${complement2}`}
						</Typography>
					)}
					<Typography
						textAlign={"left"}
						color={"secondary"}
						sx={{
							fontFamily: "Branding Medium",
							fontSize: 16,
						}}
					>
						{`${address.cp} ${city}, ${country}`}
					</Typography>
				</Box>

				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						maxWidth: "100%",
						flexWrap: "nowrap",
					}}
				>
					{((isSubAddress === undefined && selected) || isSubAddress === true) && showMessage && (
						<GradientTypo fontFamily={"Branding SemiBold"} fontSize={16}>
							Adresse actuelle pour votre abonnement
						</GradientTypo>
					)}
				</Box>
			</Box>
		</InexCard>
	);
};

export default AddressCard;
