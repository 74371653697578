import React from "react";
import { View, Svg, Defs, LinearGradient, Stop, Rect, Text } from "@react-pdf/renderer";
import { mainStyles } from "../../routes/SeeOrder";

const GradientLine = ({ ...props }) => {
	return (
		<View
			style={{
				flexGrow: 1,
				width: "100%",
				height: "3px",
				maxHeight: "3px",
				borderRadius: "1.5px",
				overflow: "hidden",
				...props.style,
			}}
			{...props}
		>
			<Svg
				width="100%"
				viewBox={"0 0 1500 5"}
				height={"3px"}
				style={{
					width: "100%",
					minWidth: "100%",
				}}
			>
				<Defs>
					<LinearGradient id="myLinearGradient">
						<Stop offset="0%" stopColor="rgba(108,52,144,1)" />
						<Stop offset="33%" stopColor="rgba(73,74,146,1)" />
						<Stop offset="66%" stopColor="rgba(46,107,164,1)" />
						<Stop offset="100%" stopColor="rgba(85,168,217,1)" />
					</LinearGradient>
				</Defs>

				<Rect
					x={0}
					y={0}
					rx={2.5}
					ry={2.5}
					width={"100%"}
					height={"5px"}
					fill="url('#myLinearGradient')"
					style={{
						width: "100%",
						minWidth: "100%",
					}}
				/>
			</Svg>
		</View>
	);
};

export default GradientLine;
