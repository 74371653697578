import React from "react";
import { Grid, styled, Typography, useTheme } from "@mui/material";
import { useIsMobile } from "../../hooks/responsive";
import InexCard from "../InexCard";
import chevron_down from "../../resources/images/svg/dropdown_arrow.svg";
import { useSearchParams } from "react-router-dom";

const Title = styled(Typography)(({ theme, selected }) => {
	return {
		fontFamily: "Branding SemiBold",
		fontSize: 16,
		color: "#162A41",
	};
});

const FaqQuestions = ({ questions }) => {
	// const { data: questions } = useGetCategoryQuestionsQuery({ id: 8 });
	const isMobile = useIsMobile();
	const theme = useTheme();
	let [searchParams, setSearchParams] = useSearchParams();

	const renderQuestionItem = (item, index) => {
		return (
			<Grid item xs={12} key={item.idquestion}>
				<InexCard
					sx={{
						display: "flex",
						flexDirection: "row",
						padding: (theme) => theme.spacing(isMobile ? 1 : 3),
						paddingTop: theme.spacing(isMobile ? 1 : 2),
						paddingBottom: theme.spacing(isMobile ? 1 : 2),
						alignItems: "center",
						borderRadius: "8px !important",
						boxShadow: "box-shadow: 0 2px 29px 0 rgba(22,42,65,0.2)",
						justifyContent: "space-between",
					}}
					onClick={() => {
						setSearchParams({ categorie: searchParams.get("categorie"), question: item.idquestion });
					}}
				>
					<Title>{item.titre}</Title>
					<img src={chevron_down} style={{ width: 16, rotate: "270deg" }} />
				</InexCard>
			</Grid>
		);
	};

	return (
		<Grid container spacing={2}>
			{questions?.map(renderQuestionItem)}
		</Grid>
	);
};

export default FaqQuestions;
