import React from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Title } from "../../../../routes/Abonnement";
import InexButton from "../../../InexButton";
import InexTextField from "../../../InexTextField";

const ModalCancelSubscription = ({ open, onClose }) => {
	const [text, setText] = React.useState(null);

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth={"xl"}>
			<DialogTitle>
				<Title>Aidez-nous à améliorer nos services !</Title>
			</DialogTitle>
			<DialogContent>
				<InexTextField
					label={"Votre avis est précieux : en nous expliquant pourquoi vous ne souhaitez plus renouveler votre abonnement, vous nous donnez une chance d'améliorer nos services et de vous offrir une meilleure expérience à l'avenir."}
					value={text ?? ""}
					onChange={(e) => {
						setText(e.target.value);
					}}
					multiline
					rows={5}
					formControlSx={{
						width: "100%",
					}}
				/>
			</DialogContent>
			<DialogActions>
				<InexButton
					onClick={onClose}
					text={"Ne pas envoyer d'avis"}
					textSx={{
						color: (theme) => theme.palette.secondary.main,
					}}
					sx={{
						width: 200,
						background: "transparent",
						"&:hover": {
							borderColor: (theme) => theme.palette.secondary.main,
							background: (theme) => theme.palette.grey[300],
						},
					}}
					textWithGradient={false}
					disableBackground
				/>
				<InexButton
					onClick={() => {
						onClose(text);
					}}
					text={"Envoyer mon avis"}
					sx={{ width: "240px" }}
				/>
			</DialogActions>
		</Dialog>
	);
};

export default ModalCancelSubscription;
