import React from "react";
import { Grid, Typography } from "@mui/material";
import { Title } from "../../routes/Abonnement";
import moment from "moment/moment";
import InexButton from "../InexButton";
import DonationModal from "./DonationModal";
import { useUpdateDonationMutation } from "../../store/api/donation";
import DeleteConfirmDialog from "../profil/DeleteConfirmDialog";
import TipArguments from "./TipArguments";
import InexCard from "../InexCard";

const DonationInfo = ({ currentDonation, refetch }) => {
	const [updateDonation, { isLoading }] = useUpdateDonationMutation();

	const _donationAmount = React.useMemo(() => {
		if (!currentDonation?.don) return;
		return currentDonation?.don / 100;
	}, [currentDonation]);

	const _donationFrequency = React.useMemo(() => {
		if (!currentDonation?.typedon) return;
		switch (currentDonation?.typedon) {
			case 1:
				return "mois";
			case 2:
				return "ans";
		}
		return "autre";
	}, [currentDonation]);

	const _nextRenouv = React.useMemo(() => {
		if (!currentDonation?.typedon) return;
		return moment(currentDonation.daterenouv * 1000).format("DD MMMM YYYY");
	});

	const [donationModalOpen, setDonationModalOpen] = React.useState(false);
	const [uniqueDonation, setUniqueDonation] = React.useState(false);
	const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);

	return (
		<React.Fragment>
			<Grid item xs={12}>
				<Title>Mes donations</Title>
			</Grid>
			{!!currentDonation &&
			currentDonation?.typedon !== 0 &&
			currentDonation?.typerenouv !== 0 &&
			_donationAmount !== 0 ? (
				<Grid item xs={12}>
					<Typography fontFamily={"Branding Bold"} fontSize={18} color={"#182B43"} lineHeight={"23px"}>
						Vous avez actuellement un don actif de
						<Typography
							fontFamily={"Branding Bold"}
							fontSize={18}
							color={"#EE848E"}
							display={"inline"}
							sx={{ cursor: "pointer" }}
							variant={"span"}
						>
							{" "}
							{_donationAmount} euros
						</Typography>{" "}
						qui se renouvelle tous les
						<Typography
							fontFamily={"Branding Bold"}
							fontSize={18}
							color={"#EE848E"}
							display={"inline"}
							sx={{ cursor: "pointer" }}
							variant={"span"}
						>
							{" "}
							{_donationFrequency}
						</Typography>{" "}
						, le prochain renouvellement est prévu le
						<Typography
							fontFamily={"Branding Bold"}
							fontSize={18}
							color={"#EE848E"}
							display={"inline"}
							sx={{ cursor: "pointer" }}
							variant={"span"}
						>
							{" "}
							{_nextRenouv}
						</Typography>
					</Typography>
					<Grid container spacing={2} mt={2}>
						<Grid item xs={12} md={6}>
							<InexButton
								text={"Modifier ma donation"}
								textWithGradient={false}
								textSx={{
									color: (theme) => theme.palette.text.secondary,
								}}
								sx={{
									width: "100%",
									backgroundColor: "white",
								}}
								onClick={() => {
									setDonationModalOpen(currentDonation);
								}}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<InexButton
								text={"Je souhaite faire un don supplémentaire"}
								sx={{
									width: "100%",
								}}
								onClick={() => {
									setDonationModalOpen(currentDonation);
									setUniqueDonation(true);
								}}
							/>
						</Grid>
						<Grid item xs={12}>
							<InexButton
								text={"Supprimer ma donation"}
								textWithGradient={false}
								disableBackground={true}
								textSx={{
									color: (theme) => {
										return theme.palette.grey["500"];
									},
								}}
								sx={{
									width: "100%",
									"&:hover": {
										border: "none",
										"& > p": {
											color: (theme) => theme.palette.error.main,
										},
									},
								}}
								onClick={() => {
									setOpenDeleteDialog(true);
								}}
							/>
						</Grid>
					</Grid>
				</Grid>
			) : (
				<Grid item xs={12} container>
					<Grid item xs={12}>
						<Typography fontFamily={"Branding Bold"} fontSize={18} color={"#182B43"} lineHeight={"23px"}>
							Vous n'avez aucune donation en cours
						</Typography>
					</Grid>
					<Grid item xs={12} sm={10} md={5} lg={4} xl={3}>
						<InexButton
							text={"Je souhaite faire un don"}
							sx={{
								width: "100%",
								marginTop: 2,
							}}
							onClick={() => {
								setDonationModalOpen(true);
							}}
						/>
					</Grid>
					<Grid container xs={12} item mt={5}>
						<InexCard>
							<TipArguments />
						</InexCard>
					</Grid>
				</Grid>
			)}
			<DonationModal
				open={donationModalOpen}
				uniqueDonation={uniqueDonation}
				onClose={() => {
					setDonationModalOpen(false);
					setUniqueDonation(false);
				}}
				onValidate={() => {
					refetch();
				}}
			/>
			<DeleteConfirmDialog
				open={openDeleteDialog}
				title={"Êtes vous sûr(e) de vouloir supprimer votre donation ?"}
				onClose={(confirm = false) => {
					setOpenDeleteDialog(false);
					if (confirm === true) {
						updateDonation({
							...currentDonation,
							typedon: 0,
							typerenouv: 0,
							montant: 0,
							idcb: 0,
							idiban: 0,
							daterenouv: 0,
						}).then(() => {
							refetch();
						});
					}
				}}
			/>
		</React.Fragment>
	);
};

export default DonationInfo;
