import React from "react";

const useTabTitle = ({ customTitle = "", htmlTag = "h5" }) => {
	React.useEffect(() => {
		if (!!customTitle) {
			document.title = customTitle;
		} else {
			const allH2 = document.querySelectorAll(htmlTag);
			if (allH2[0]) {
				document.title = allH2[0].innerHTML;
			} else {
				document.title = "Module Inexploré";
			}
		}
	}, [customTitle]);
};

export default useTabTitle;
