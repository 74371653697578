import React from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import InexButton from "../InexButton";
import { useSelector } from "react-redux";
import { getInreesId, getUser } from "../../store/reducers/authSlice";
import { useGetCurrentSubscriptionsQuery } from "../../store/api/subscriptions";
import AddressCard from "../profil/AddressCard";
import { useGetUserAddressesQuery } from "../../store/api/user";
import { useAuthToken } from "../../hooks/auth";

const SelectAddressForOrderPDF = ({ open, onClose, ...props }) => {
	const inreesId = useSelector(getInreesId);
	const authToken = useAuthToken();
	const user = useSelector(getUser);

	const [selectedAddress, setSelectedAddress] = React.useState(null);
	const [subAddress, setSubAddress] = React.useState(null);
	const { data: infos, isFetching: isFetchingAbos } = useGetCurrentSubscriptionsQuery(
		{},
		{
			skip: !user,
		}
	);

	React.useEffect(() => {
		if (infos?.newabos) {
			setSelectedAddress(infos.newabos.idadresse);
			setSubAddress(infos.newabos.idadresse);
		}
	}, [infos]);

	const [addresses, setAddresses] = React.useState(null);
	const { data, isFetching, refetch } = useGetUserAddressesQuery(
		{
			idinexplore: inreesId,
			authToken,
		},
		{
			skip: !inreesId || !authToken || !user,
		}
	);

	React.useEffect(() => {
		if (data) {
			setAddresses(data.infos);
		}
	}, [data]);

	const formIsValid = React.useMemo(() => {
		return selectedAddress !== null;
	}, [selectedAddress]);

	return (
		<Dialog open={!!open} onClose={onClose}>
			<DialogTitle>Sélectionnez l'adresse à mentionner sur votre facture</DialogTitle>
			<DialogContent>
				<Grid container spacing={2} mt={0} direction={"row"} wrap={"wrap"}>
					{addresses?.map((element, index) => {
						return (
							<Grid key={index} item xs={12} sm={12} md={6} lg={6} xl={4}>
								<AddressCard
									address={element}
									isSubAddress={element.idadresse === subAddress}
									selected={element.idadresse === selectedAddress}
									canDelete={addresses.length > 1}
									canEdit={false}
									selectable={true}
									onClick={() => {
										setSelectedAddress(element.idadresse);
									}}
								/>
							</Grid>
						);
					})}
				</Grid>
			</DialogContent>
			<DialogActions>
				<InexButton
					onClick={onClose}
					variant={"text"}
					textWithGradient={false}
					text={"Annuler"}
					textSx={{
						color: (theme) => theme.palette.secondary.main,
					}}
					sx={{
						width: 100,
						background: "transparent",
						"&:hover": {
							borderColor: (theme) => theme.palette.secondary.main,
							background: (theme) => theme.palette.grey[300],
						},
					}}
				/>
				<InexButton
					onClick={(e) => {
						onClose(e, open, addresses.find(e => e.idadresse === selectedAddress));
					}}
					disabled={!formIsValid}
					variant={"text"}
					textWithGradient={false}
					text={"Générer ma facture"}
					textSx={{
						color: "white",
					}}
					sx={{
						width: 200,
					}}
				/>
			</DialogActions>
		</Dialog>
	);
};

export default SelectAddressForOrderPDF;
