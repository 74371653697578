import React from "react";
import InexButton from "../InexButton";
import {
	Alert,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	InputAdornment, Popover
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../store/reducers/authSlice";
import InexTextField from "../InexTextField";
import { useSetUserPasswordMutation } from "../../store/api/user";
import { setShowSnackbar, setSnackbarSeverity, setSnackbarText } from "../../store/reducers/snackbarSlice";
import resetIcon from "../../resources/images/svg/resetIcon.svg";
import { VisibilityOffRounded, VisibilityRounded } from "@mui/icons-material";

const PasswordChangeModal = ({ onClose, ...props }) => {
	const user = useSelector(getUser);
	const dispatch = useDispatch();

	const [oldpass, setOldpass] = React.useState("");
	const [pass1, setPass1] = React.useState("");
	const [pass2, setPass2] = React.useState("");

	const [error, setError] = React.useState(null);
	const [loading, setLoading] = React.useState(false);

	// const [resetPassword, { isFetching }] = useLazyResetUserPasswordQuery();
	const [setPassword] = useSetUserPasswordMutation();

	const [showPasswords, setShowPasswords] = React.useState(false);

	const handleValidate = () => {
		if (oldpass === "") {
			setError("Veuillez entrer votre mot de passe actuel");
			return;
		}
		if (pass1 === "" || pass2 === "") {
			setError("Veuillez entrer le nouveau mot de passe");
			return;
		}
		if (pass1 !== pass2) {
			setError("Veuillez confirmer le nouveau mot de passe");
			return;
		}
		if (oldpass === pass1) {
			setError("le nouveau mot de passe est identique au précédent");
			return;
		}
		setError(null);
		setLoading(true);
		setPassword({
			oldpass: oldpass,
			pass: pass1,
		}).then((res) => {
			setLoading(false);
			if (res.error && res.error.status === 404) {
				setError("Mot de passe actuel invalide.");
			} else {
				onClose();
				setOldpass("");
				setPass1("");
				setPass2("");
				dispatch(setShowSnackbar(true));
				dispatch(setSnackbarSeverity("success"));
				dispatch(setSnackbarText("Vos informations ont bien été mises à jour."));
			}
		});
	};

	return (
		<Dialog open={props.open} onClose={onClose} {...props}>
			<DialogTitle color={"secondary"}>
				{"Veuillez entrer votre ancien mot de passe ainsi que le nouveau"}
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					{error && (
						<Grid item xs={12}>
							<Alert
								severity={"error"}
								sx={{
									width: "100%",
								}}
							>
								{error}
							</Alert>
						</Grid>
					)}
					<Grid item xs={12}>
						<InexTextField
							type={showPasswords ? "text" : "password"}
							label={"Mot de passe actuel"}
							formControlSx={{ width: "100%" }}
							value={oldpass}
							onChange={(e) => {
								setOldpass(e.target.value);
							}}
							disabled={loading}
							endAdornment={
								<InputAdornment position={"end"}>
									<IconButton
										size={"small"}
										onClick={() => {
											setShowPasswords(!showPasswords);
										}}
										sx={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											flexDirection: "column",
										}}
									>
										{showPasswords ? <VisibilityRounded color={"secondary"} /> : <VisibilityOffRounded />}
									</IconButton>
								</InputAdornment>
							}
						/>
					</Grid>
					<Grid item xs={12}>
						<InexTextField
							type={showPasswords ? "text" : "password"}
							label={"Nouveau mot de passe"}
							formControlSx={{ width: "100%" }}
							value={pass1}
							onChange={(e) => {
								setPass1(e.target.value);
							}}
							disabled={loading}
							endAdornment={
								<InputAdornment position={"end"}>
									<IconButton
										size={"small"}
										onClick={() => {
											setShowPasswords(!showPasswords);
										}}
										sx={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											flexDirection: "column",
										}}
									>
										{showPasswords ? <VisibilityRounded color={"secondary"} /> : <VisibilityOffRounded />}
									</IconButton>
								</InputAdornment>
							}
						/>
					</Grid>
					<Grid item xs={12}>
						<InexTextField
							success={pass1 === pass2 && pass2.length > 0}
							error={pass1 !== pass2 && pass2.length > 0}
							type={showPasswords ? "text" : "password"}
							label={"Confirmez le nouveau mot de passe"}
							formControlSx={{ width: "100%" }}
							value={pass2}
							onChange={(e) => {
								setPass2(e.target.value);
							}}
							disabled={loading}
							endAdornment={
								<InputAdornment position={"end"}>
									<IconButton
										size={"small"}
										onClick={() => {
											setShowPasswords(!showPasswords);
										}}
										sx={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											flexDirection: "column",
										}}
									>
										{showPasswords ? <VisibilityRounded color={"secondary"} /> : <VisibilityOffRounded />}
									</IconButton>
								</InputAdornment>
							}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<InexButton
					onClick={onClose}
					variant={"text"}
					textWithGradient={false}
					text={"Annuler"}
					textSx={{
						color: (theme) => theme.palette.secondary.main,
					}}
					sx={{
						width: 100,
						background: "transparent",
						"&:hover": {
							borderColor: (theme) => theme.palette.secondary.main,
							background: (theme) => theme.palette.grey[300],
						},
					}}
				/>
				<InexButton
					onClick={() => {
						// resetPassword({
						// 	email: user.email,
						// }).then(({ data, ...rest }) => {
						// 	console.log(data);
						// 	onClose();
						// });
						handleValidate();
					}}
					disabled={false}
					loading={loading}
					variant={"text"}
					textWithGradient={false}
					text={"Valider"}
					textSx={{
						color: "white",
					}}
					sx={{
						width: 100,
					}}
				/>
			</DialogActions>
		</Dialog>
	);
};

export default PasswordChangeModal;
