import React from "react";
import { Document, Page, View, Text, Link, Font, StyleSheet, PDFViewer, Image } from "@react-pdf/renderer";
import { mainStyles } from "./Invoice";

const styles = StyleSheet.create({
	container: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
		flexGrow: 10,
		marginTop: 10,
	},
	divider: {
		width: "100%",
		display: "flex",
		flexGrow: 1,
	},
	header: {
		width: "100%",
		height: 30,
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		backgroundColor: "rgb(181,181,181)",
		borderTop: "1px solid rgb(181,181,181)",
		borderBottom: "1px solid rgb(181,181,181)",
		borderLeft: "1px solid rgb(181,181,181)",
	},
	headerCell: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		height: "100%",
		width: "15%",
		borderRight: "1px solid rgb(181,181,181)",
	},
	row: {
		width: "100%",
		height: 30,
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		backgroundColor: "rgb(234,234,234)",
		borderBottom: "1px solid rgb(181,181,181)",
		borderLeft: "1px solid rgb(181,181,181)",
	},
	rowOdd: {
		backgroundColor: "rgb(247,247,247)",
	},
	cell: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		height: "100%",
		width: "15%",
		borderRight: "1px solid rgb(181,181,181)",
	},
	totalsContainer: {
		display: "flex",
		justifyContent: "flex-end",
		alignItems: "flex-end",
		marginBottom: 30,
	},
	totals: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "flex-end",
		backgroundColor: "rgb(181,181,181)",
		padding: 10,
		minWidth: "210px",
		width: "210px",
	},
	rowTotal: {
		display: "flex",
		flexDirection: "row",
		width: "100%",
		minWidth: "100%",
		justifyContent: "space-between",
	},
});

const formatPrice = (price, symbol = "€") => {
	return `${price.toFixed(2).replace(".", ",")}${symbol}`;
};

export const formatText = (text) => {
	if (!text) return "";
	const textToReplace = text.substring(1, text.length);
	return text.charAt(0) + textToReplace.replaceAll("T", " T").replaceAll("Y", " Y").replaceAll("'", " '");
};

const InvoiceContent = ({ order, ...props }) => {
	const hasPromo = order.promo && !Array.isArray(order.promo);
	const promo = hasPromo ? order.promo.montant / 100 : 0;
	const ttcTotal = order.total / 100;
	const totalTaxes = order.tvaval / 100;
	const htTotal = ttcTotal - totalTaxes + promo;
	const tip = order.don / 100;

	const details = order?.details;

	return (
		<View style={styles.container}>
			<View style={styles.header}>
				<View style={{ ...styles.headerCell, width: "40%" }}>
					<Text style={{ ...mainStyles.text, ...mainStyles.textSmall }}>Désignation</Text>
				</View>
				<View style={{ ...styles.headerCell }}>
					<Text style={{ ...mainStyles.text, ...mainStyles.textSmall }}>Quantité</Text>
				</View>
				<View style={{ ...styles.headerCell }}>
					<Text style={{ ...mainStyles.text, ...mainStyles.textSmall }}>
						{formatText("Prix unitaire HT")}
					</Text>
				</View>
				<View style={{ ...styles.headerCell }}>
					<Text style={{ ...mainStyles.text, ...mainStyles.textSmall }}>{formatText("TVA")}</Text>
				</View>
				<View style={{ ...styles.headerCell }}>
					<Text style={{ ...mainStyles.text, ...mainStyles.textSmall }}>{formatText("Prix TTC")}</Text>
				</View>
			</View>

			{details.map((detail, index) => {
				let style = { ...styles.row };
				if (index % 2) style = { ...styles.row, ...styles.rowOdd };

				const ttcTotal = detail.montant;
				const htTotal = ttcTotal / (100 + detail.tva / 100);
				return (
					<View style={style} key={index} break={index > 0 && index % 16 === 0}>
						<View style={{ ...styles.cell, width: "40%" }}>
							<Text style={{ ...mainStyles.text, ...mainStyles.textSmall }}>
								{formatText(detail.abo.titre)}
							</Text>
						</View>
						<View style={{ ...styles.cell }}>
							<Text style={{ ...mainStyles.text, ...mainStyles.textSmall }}>1</Text>
						</View>
						<View style={{ ...styles.cell }}>
							<Text style={{ ...mainStyles.text, ...mainStyles.textSmall }}>{formatPrice(htTotal)}</Text>
						</View>
						<View style={{ ...styles.cell }}>
							<Text style={{ ...mainStyles.text, ...mainStyles.textSmall }}>
								{`${(detail.tva / 100).toFixed(2)}%`}
							</Text>
						</View>
						<View style={{ ...styles.cell }}>
							<Text style={{ ...mainStyles.text, ...mainStyles.textSmall }}>
								{`${formatPrice(ttcTotal / 100)}`}
							</Text>
						</View>
					</View>
				);
			})}

			{tip && (
				<View style={{ ...styles.row, ...styles.rowOdd }}>
					<View style={{ ...styles.cell, width: "40%" }}>
						<Text style={{ ...mainStyles.text, ...mainStyles.textSmall }}>{formatText("Donation")}</Text>
					</View>
					<View style={{ ...styles.cell }}>
						<Text style={{ ...mainStyles.text, ...mainStyles.textSmall }}>1</Text>
					</View>
					<View style={{ ...styles.cell }}>
						<Text style={{ ...mainStyles.text, ...mainStyles.textSmall }}>{formatPrice(tip)}</Text>
					</View>
					<View style={{ ...styles.cell }}>
						<Text style={{ ...mainStyles.text, ...mainStyles.textSmall }}>{`0,00%`}</Text>
					</View>
					<View style={{ ...styles.cell }}>
						<Text style={{ ...mainStyles.text, ...mainStyles.textSmall }}>{`${formatPrice(tip)}`}</Text>
					</View>
				</View>
			)}
			<View style={styles.divider} />
			<View style={styles.totalsContainer}>
				<View style={styles.totals}>
					<View style={styles.rowTotal}>
						<Text style={{ ...mainStyles.text, ...mainStyles.textSemiBold, textAlign: "left" }}>
							{formatText("Total HT : ")}
						</Text>
						<Text style={{ ...mainStyles.text, ...mainStyles.textBold, textAlign: "right" }}>
							{`${formatPrice(htTotal)}`}
						</Text>
					</View>
					<View style={styles.rowTotal}>
						<Text style={{ ...mainStyles.text, ...mainStyles.textSemiBold, textAlign: "left" }}>
							{formatText(`Total TVA : `)}
						</Text>
						<Text style={{ ...mainStyles.text, ...mainStyles.textBold }}>
							{`${formatPrice(totalTaxes)}`}
						</Text>
					</View>
					{hasPromo && (
						<View style={styles.rowTotal}>
							<Text style={{ ...mainStyles.text, ...mainStyles.textSemiBold, textAlign: "left" }}>
								{formatText("Rémise : ")}
							</Text>
							<Text style={{ ...mainStyles.text, ...mainStyles.textBold, textAlign: "right" }}>
								{`-${formatPrice(promo)}`}
							</Text>
						</View>
					)}
					{/*<View style={styles.rowTotal}>*/}
					{/*	<Text style={{ ...mainStyles.text, ...mainStyles.textSemiBold, textAlign: "left" }}>*/}
					{/*		{formatText(`TVA : `)}*/}
					{/*	</Text>*/}
					{/*	<Text style={{ ...mainStyles.text, ...mainStyles.textBold }}>*/}
					{/*		{formatPrice(tvaTaux, "%")}*/}
					{/*	</Text>*/}
					{/*</View>*/}
					<View style={{ ...styles.rowTotal, marginTop: 10 }}>
						<Text
							style={{
								...mainStyles.text,
								...mainStyles.textSemiBold,
								...mainStyles.textBig,
								textAlign: "left",
							}}
						>
							{formatText(`Total payé TTC : `)}
						</Text>
						<Text
							style={{ ...mainStyles.text, ...mainStyles.textBold, ...mainStyles.textBig }}
						>{`${formatPrice(ttcTotal)}`}</Text>
					</View>
				</View>
			</View>
		</View>
	);
};

export default InvoiceContent;
