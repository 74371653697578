import { store } from "../store";
import { encode } from "querystring";
import { api } from "./rtkApi";

export const subscriptionsApi = api.injectEndpoints({
	endpoints: (build) => ({
		getCurrentSubscriptions: build.query({
			query: () => {
				const now = new Date();
				const ts = now.toISOString()

				const state = store.getState();
				let authToken = state.auth.authToken;
				return {
					method: "POST",
					url: `/abo/get-abos?ts=${ts}`,
					headers: {
						authorization: "Bearer " + authToken,
						"Content-Type": "application/x-www-form-urlencoded",
					},
				};
			},
		}),
		getBillingFrequency: build.query({
			query: ({ zone, mag, digital, tv }) => {
				const state = store.getState();
				let authToken = state.auth.authToken;
				return {
					method: "POST",
					url: `/abo/freq`,
					headers: {
						authorization: "Bearer " + authToken,
						"Content-Type": "application/x-www-form-urlencoded",
					},
					body: encode({
						digital,
						tv,
						mag,
						tunnel: 0,
						zone,
					}),
				};
			},
			transformResponse(baseQueryReturnValue, meta, arg) {
				return baseQueryReturnValue?.frequences;
			},
		}),
		updateSubscription: build.mutation({
			query: ({ digital, tv, mag, zone, frequence, idadresse, typerenouv, idcb, idiban, message }) => {
				const state = store.getState();
				let authToken = state.auth.authToken;
				return {
					method: "POST",
					url: `/abo/update`,
					headers: {
						authorization: "Bearer " + authToken,
						"Content-Type": "application/x-www-form-urlencoded",
					},
					body: encode({
						digital,
						tv,
						mag,
						zone,
						frequence,
						idadresse,
						typerenouv,
						idcb,
						idiban,
						message
					}),
				};
			},
		}),
		createSepa: build.query({
			query: ({ idabo }) => {
				const state = store.getState();
				let authToken = state.auth.authToken;
				let idinexplore = state.auth.inreesId;
				return {
					method: "POST",
					url: `/abo/creer-sepa`,
					headers: {
						authorization: "Bearer " + authToken,
						"Content-Type": "application/x-www-form-urlencoded",
					},
					body: encode({
						idinexplore: parseInt(idinexplore),
						idabo,
					}),
				};
			},
			transformResponse(baseQueryReturnValue, meta, arg) {
				return baseQueryReturnValue;
			},
		}),
	}),
	overrideExisting: true,
});

export const {
	useGetCurrentSubscriptionsQuery,
	useGetBillingFrequencyQuery,
	useUpdateSubscriptionMutation,
	useLazyCreateSepaQuery,
} = subscriptionsApi;
