import React from "react";
import { ContentContainer } from "./Abonnement";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useIsLoggedIn } from "../hooks/auth";
import { CancelRounded } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import InexCard from "../components/InexCard";
import InexButton from "../components/InexButton";

const DonationFailure = () => {
	const navigate = useNavigate();
	const isLoggedIn = useIsLoggedIn();

	const { montant } = useParams();

	if (montant == null) {
		navigate(isLoggedIn ? "/profil" : "/faq");
	}

	return (
		<ContentContainer container item className={"content-background"}>
			<InexCard>
				<Grid container flexDirection={"column"}>
					<Grid
						item
						xs={12}
						container
						flexDirection={"row"}
						alignItems={"center"}
						justifyContent={"center"}
						spacing={2}
					>
						<Grid item xs={12} alignItems={"center"} justifyContent={"center"}>
							<CancelRounded
								color={"error"}
								sx={{
									width: "50px",
									height: "50px",
								}}
							/>
						</Grid>
						<Grid item flexDirection={"column"} xs={12}>
							<Typography
								variant={"h5"}
								sx={{
									fontFamily: "Branding Bold",
								}}
							>
								{`Oups`}
							</Typography>
							<Typography
								variant={"h6"}
								sx={{
									fontFamily: "Branding SemiBold",
								}}
							>
								{`Il semblerais qu'il y ai eu une erreur lors du paiement de votre donation.`}
							</Typography>
						</Grid>
					</Grid>
					<Grid item xs={12} pt={3}>
						<InexButton
							text={"Revenir à la page de donation"}
							sx={{
								width: "100%",
							}}
							onClick={() => {
								navigate("/don");
							}}
						/>
					</Grid>
				</Grid>
			</InexCard>
		</ContentContainer>
	);
};

export default DonationFailure;
