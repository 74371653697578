import React from "react";
import { useLogUserIn, useRedirectToLogin } from "../hooks/auth";

const withAuth = (Component) => (props) => {
	useLogUserIn();
	useRedirectToLogin();
	return <Component {...props} />;
};

export default withAuth;
