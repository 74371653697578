import React from "react";
import { Document, Page, View, Text, Link, Font, StyleSheet, PDFViewer, Image } from "@react-pdf/renderer";
import { mainStyles } from "./Invoice";

const styles = StyleSheet.create({
	container: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
	},
});

const InvoiceFooter = ({ order, ...props }) => {
	return (
		<View style={styles.container}>
			<Text style={{ ...mainStyles.text, ...mainStyles.textSmall, marginTop: 10 }}>
				{"Paiement : Acquitté"}
			</Text>
			<Text style={{ ...mainStyles.text, ...mainStyles.textSmall }}>
				{"INREES - SAS au capital de 60 000 euros / 12 avenue Jean Jaurès - 92120 Montrouge"}
			</Text>
			<Text style={{ ...mainStyles.text, ...mainStyles.textSmall }}>{"SIRET 529 179 582 00034"}</Text>
			<Text style={{ ...mainStyles.text, ...mainStyles.textSmall }}>
				{"TVA intra-communautaire : FR92 529 179 582"}
			</Text>
		</View>
	);
};

export default InvoiceFooter;
