import React, { useCallback, useEffect, useMemo } from "react";
import { useGetUserAddressesQuery } from "../../../../store/api/user";
import { Box, Skeleton, Typography } from "@mui/material";
import InexSelect from "../../../InexSelect";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";

const AbonnementAddress = ({ idadresse, selectedAddress, setSelectedAddress }) => {
	const { data: userAdresses } = useGetUserAddressesQuery({});
	const navigate = useNavigate();
	const data = useMemo(() => {
		if (userAdresses?.infos) {
			return [...userAdresses?.infos, { newItem: true }];
		}
	}, [userAdresses]);

	useEffect(() => {
		if (data) {
			if (idadresse) {
				const item = userAdresses?.infos.find((userAddr) => userAddr.idadresse === idadresse);
				setSelectedAddress(item);
			}
		}
	}, [data]);

	const onValueChange = useCallback(
		({ target }) => {
			if (target?.value?.newItem) {
				navigate("/profil");
				return;
			}
			setSelectedAddress(target?.value);
		},
		[data]
	);
	if (!data)
		return (
			<Skeleton
				sx={{
					backgroundColor: "white",
					boxShadow: (theme) => theme.shadows[25],
					borderRadius: "8px",
				}}
				variant="rectangular"
				width={"100%"}
				height={"121px"}
				animation={"pulse"}
			/>
		);
	return (
		<InexSelect
			value={selectedAddress}
			onChange={onValueChange}
			options={data}
			label={"Votre magazine est livré à cette adresse"}
			displayEmpty
			renderValue={(selected) => {
				if (!selected) {
					return <em style={{ marginTop: 20, display: "flex", opacity: 0.5 }}>Sélectionner une adresse</em>;
				}
				return (
					<Box sx={{ paddingY: 1 }}>
						<Typography>
							{selected.prenom} {selected.nom.toUpperCase()},<br />
							{`${selected.complement1}`}
							{selected?.complement1 && <br />}
							{`${selected.cp}, ${selected.ville}, ${selected.pays}`}
						</Typography>
						<div>{selected?.complement2}</div>
					</Box>
				);
			}}
			renderOptions={(option) => {
				if (option?.newItem) {
					return (
						<Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
							<AddIcon />
							<Typography sx={{ textDecoration: "underline" }}>
								Ajouter une adresse ou gérer votre carnet d'adresse
							</Typography>
						</Box>
					);
				}
				return (
					<Box sx={{ paddingY: 1 }}>
						<Typography>
							{option.prenom} {option.nom.toUpperCase()},<br />
							{`${option.complement1}`}
							{option?.complement1 && <br />}
							{`${option.cp}, ${option.ville}, ${option.pays}`}
						</Typography>
						<div>{option?.complement2}</div>
					</Box>
				);
			}}
		/>
	);
};

export default AbonnementAddress;
