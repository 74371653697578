import { api } from "./rtkApi";
import { encode } from "querystring";
import { store } from "../store";

export const donationApi = api.injectEndpoints({
	endpoints: (build) => ({
		updateDonation: build.mutation({
			query: ({ typerenouv, daterenouv, idcb, idiban, montant, typedon }) => {
				const state = store.getState();
				let authToken = state.auth.authToken;
				return {
					method: "POST",
					url: `/abo/set-don`,
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
						authorization: "Bearer " + authToken,
					},
					body: encode({
						typerenouv,
						daterenouv,
						idcb,
						idiban,
						montant,
						typedon,
					}),
				};
			},
			transformResponse(baseQueryReturnValue, meta, arg) {
				return baseQueryReturnValue?.question;
			},
		}),
	}),
	overrideExisting: true,
});

export const { useUpdateDonationMutation } = donationApi;
