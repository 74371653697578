import React from "react";
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import InexTextField from "../InexTextField";
import InexButton from "../InexButton";
import InexAutoComplete from "../InexAutoComplete";
import { useSelector } from "react-redux";
import { getAuthToken, getInreesId } from "../../store/reducers/authSlice";
import { useLazyCreateUserAddressQuery, useLazyEditUserAddressQuery } from "../../store/api/user";
import { useLazyGetPaysQuery } from "../../store/api/address";

const validationSteps = [
	{
		fieldName: "civility",
		errorMessage: "Veuillez choisir une civilité",
		checkFunc: (val) => {
			return val !== "" && val !== null && val !== undefined;
		},
	},
	{
		fieldName: "firstName",
		errorMessage: "Veuillez entrer un prénom",
		checkFunc: (val) => {
			return val !== "" && val != null;
		},
	},
	{
		fieldName: "lastName",
		errorMessage: "Veuillez entrer un nom de famille",
		checkFunc: (val) => {
			return val !== "" && val != null;
		},
	},
	{
		fieldName: "address",
		errorMessage: "Veuillez entrer une adresse",
		checkFunc: (val) => {
			return val !== "" && val != null;
		},
	},
	{
		fieldName: "city",
		errorMessage: "Veuillez entrer une ville",
		checkFunc: (val) => {
			return val !== "" && val != null;
		},
	},
	{
		fieldName: "postalCode",
		errorMessage: "Veuillez entrer un code postal",
		checkFunc: (val) => {
			return val !== "" && val != null;
		},
	},
	{
		fieldName: "country",
		errorMessage: "Veuillez choisir un pays",
		checkFunc: (val) => {
			return val !== "" && val != null;
		},
	},
];

const AddressCreateModal = ({ address, onClose, ...props }) => {
	const [error, setError] = React.useState({});
	const hasAddress = React.useMemo(() => {
		return typeof address === "object";
	}, [address]);

	React.useEffect(() => {
		if (props.open === false) setError({});
	}, [props.open]);

	const reduceFunc = (state, action) => {
		return { ...state, [action.field]: action.value };
	};
	const [formValues, setFormValue] = React.useReducer(reduceFunc, {
		civility: hasAddress ? address.civilite : "",
		firstName: hasAddress ? address.prenom : "",
		lastName: hasAddress ? address.nom : "",
		address: hasAddress ? address.adresse : "",
		comp1: hasAddress ? address.complement1 : "",
		comp2: hasAddress ? address.complement2 : "",
		postalCode: hasAddress ? address.cp : "",
		city: hasAddress ? address.ville : "",
		country: hasAddress ? address.country : { idpays: 61, pays: "France (métropolitaine)", zone: 1 },
	});

	const defaultValues = React.useMemo(() => {
		if (!address || typeof address !== "object") return;
		return {
			civility: address.civilite === "" || !address.civilite ? "Je ne souhaite pas préciser" : address.civilite,
			firstName: address.prenom,
			lastName: address.nom,
			address: address.address,
			comp1: address.complement1,
			comp2: address.complement2,
			postalCode: address.cp,
			city: address.ville,
			country: address ? address.pays : { idpays: 61, pays: "France (métropolitaine)", zone: 1 },
		};
	}, [address]);

	React.useEffect(() => {
		const isAddressValid = address && typeof address === "object";
		setFormValue({
			field: "civility",
			value: isAddressValid
				? address.civilite === "" || !address.civilite
					? "Je ne souhaite pas préciser"
					: address.civilite
				: "",
		});
		setFormValue({ field: "firstName", value: isAddressValid ? address.prenom : "" });
		setFormValue({ field: "lastName", value: isAddressValid ? address.nom : "" });
		setFormValue({ field: "address", value: isAddressValid ? address.address : "" });
		setFormValue({ field: "comp1", value: isAddressValid ? address.complement1 : "" });
		setFormValue({ field: "comp2", value: isAddressValid ? address.complement2 : "" });
		setFormValue({ field: "postalCode", value: isAddressValid ? address.cp : "" });
		setFormValue({ field: "city", value: isAddressValid ? address.ville : "" });
		setFormValue({
			field: "country",
			value: isAddressValid ? address.pays : { idpays: 61, pays: "France (métropolitaine)", zone: 1 },
		});
	}, [address]);

	const inreesId = useSelector(getInreesId);
	const authToken = useSelector(getAuthToken);

	const [fetchPays, { isFetching }] = useLazyGetPaysQuery();

	const [dataPays, setDataPays] = React.useState([]);

	const [open, setIsOpen] = React.useState(false);
	const [isLoading, setIsLoading] = React.useState(false);
	const [options, setOptions] = React.useState([]);

	React.useEffect(() => {
		if (open && options.length === 0) {
			fetchPays().then(({ data }) => {
				setDataPays(data);
				setOptions(data.pays.map((e) => e.pays));
				setIsLoading(false);
			});
		}
	}, [open, options]);

	React.useEffect(() => {
		if (isFetching === true) setIsLoading(true);
	}, [isFetching]);

	const [createAddress, { isFetching: isCreatingAddress }] = useLazyCreateUserAddressQuery();
	const [editAddress, { isFetching: isEditingAddress }] = useLazyEditUserAddressQuery();

	const handleValidation = (fieldName) => {
		let hasError = false;
		const tmp = {};
		if (!fieldName)
			validationSteps.forEach((element) => {
				if (hasAddress === true && element.fieldName === "country") return;
				if (element.checkFunc(formValues[element.fieldName]) === false) {
					tmp[element.fieldName] = element.errorMessage;
					hasError = true;
				} else {
					tmp[element.fieldName] = undefined;
				}
			});
		else {
			const validationStep = validationSteps.find((e) => e.fieldName === fieldName);
			if (validationStep) {
				if (validationStep.checkFunc(formValues[fieldName]) === false)
					tmp[validationStep.fieldName] = validationStep.errorMessage;
				else tmp[validationStep.fieldName] = undefined;
			}
		}
		setError(tmp);
		return hasError;
	};

	const handleValidate = () => {
		if (handleValidation() === false) {
			const variables = {
				authToken,
				idinexplore: inreesId,
				civilite: formValues.civility,
				nom: formValues.lastName,
				prenom: formValues.firstName,
				address: formValues.address,
				idadresse: hasAddress ? address.idadresse : null,
				complement1: formValues.comp1,
				complement2: formValues.comp2,
				ville: formValues.city,
				cp: formValues.postalCode,
				idpays: hasAddress ? address.idpays : formValues.country.idpays,
			};
			const func = hasAddress === true ? editAddress : createAddress;
			func(variables)
				.then((data) => {
					onClose(true);
				})
				.catch((error) => {
					// TODO : Tell the user there was an error
				});
		}
	};

	if (isCreatingAddress === true) return;

	return (
		<Dialog open={props.open} onClose={onClose} {...props}>
			{isCreatingAddress !== true || isEditingAddress !== true ? (
				<>
					<DialogTitle color={"secondary"} fontFamily={"Branding Bold"}>
						{hasAddress ? "Modifier une adresse" : "Ajouter une adresse"}
					</DialogTitle>
					<DialogContent>
						<Grid
							container
							spacing={2}
							sx={{
								"&:focus": {
									outline: "none",
								},
							}}
						>
							<Grid item xs={12}>
								<Typography color={"secondary"} textAlign={"left"} variant={"caption"}>
									* Champs obligatoires
								</Typography>
							</Grid>
							<Grid item xs={12} md={12} container>
								<Grid item xs={12}>
									<InexAutoComplete
										value={formValues.civility}
										defaultValue={address ? defaultValues.civility : undefined}
										options={["Monsieur", "Madame", "Je ne souhaite pas préciser"]}
										label={"Civilité*"}
										onChange={(e, value) => {
											setFormValue({ field: "civility", value });
										}}
										onBlur={() => {
											handleValidation("civility");
										}}
										error={error["civility"] !== undefined}
										helperText={error["civility"]}
									/>
								</Grid>
							</Grid>
							<Grid item xs={12} md={6}>
								<InexTextField
									label={"Prénom*"}
									defaultValue={address ? defaultValues.firstName : undefined}
									value={formValues.firstName}
									onChange={(e) => {
										setFormValue({ field: "firstName", value: e.target.value });
									}}
									onBlur={() => {
										handleValidation("firstName");
									}}
									formControlSx={{
										width: "100%",
									}}
									error={error["firstName"] !== undefined}
									helperText={error["firstName"]}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<InexTextField
									label={"Nom*"}
									defaultValue={address ? defaultValues.lastName : undefined}
									value={formValues.lastName}
									onChange={(e) => {
										setFormValue({ field: "lastName", value: e.target.value });
									}}
									onBlur={() => {
										handleValidation("lastName");
									}}
									formControlSx={{
										width: "100%",
									}}
									error={error["lastName"] !== undefined}
									helperText={error["lastName"]}
								/>
							</Grid>
							<Grid item xs={12}>
								<InexTextField
									label={"Adresse*"}
									defaultValue={address ? defaultValues.address : undefined}
									value={formValues.address}
									onChange={(e) => {
										setFormValue({ field: "address", value: e.target.value });
									}}
									onBlur={() => {
										handleValidation("address");
									}}
									formControlSx={{
										width: "100%",
									}}
									error={error["address"] !== undefined}
									helperText={error["address"]}
								/>
							</Grid>
							<Grid item xs={12} md={12}>
								<InexTextField
									label={"Complément d'adresse 1"}
									defaultValue={address ? defaultValues.comp1 : undefined}
									value={formValues.comp1}
									onChange={(e) => {
										setFormValue({ field: "comp1", value: e.target.value });
									}}
									formControlSx={{
										width: "100%",
									}}
									error={error["comp1"] !== undefined}
									helperText={error["comp1"]}
								/>
							</Grid>
							<Grid item xs={12} md={12}>
								<InexTextField
									label={"Complément d'adresse 2"}
									defaultValue={address ? defaultValues.comp2 : undefined}
									value={formValues.comp2}
									onChange={(e) => {
										setFormValue({ field: "comp2", value: e.target.value });
									}}
									formControlSx={{
										width: "100%",
									}}
									error={error["comp2"] !== undefined}
									helperText={error["comp2"]}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<InexTextField
									label={"Code postal*"}
									defaultValue={address ? defaultValues.postalCode : undefined}
									value={formValues.postalCode}
									onChange={(e) => {
										setFormValue({ field: "postalCode", value: e.target.value });
									}}
									formControlSx={{
										width: "100%",
									}}
									onBlur={() => {
										handleValidation("postalCode");
									}}
									error={error["postalCode"] !== undefined}
									helperText={error["postalCode"]}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<InexTextField
									label={"Ville*"}
									defaultValue={address ? defaultValues.city : undefined}
									value={formValues.city}
									onChange={(e) => {
										setFormValue({ field: "city", value: e.target.value });
									}}
									formControlSx={{
										width: "100%",
									}}
									onBlur={() => {
										handleValidation("city");
									}}
									error={error["city"] !== undefined}
									helperText={error["city"]}
								/>
							</Grid>
							{!hasAddress && (
								<Grid item xs={12} md={12}>
									<InexAutoComplete
										label={"Pays*"}
										options={options}
										defaultValue={address ? defaultValues.country : undefined}
										value={formValues.country.pays}
										loadingText={"Chargement des pays disponibles..."}
										onOpen={() => setIsOpen(true)}
										onClose={() => setIsOpen(false)}
										loading={isFetching || isLoading}
										error={error["country"] !== undefined}
										helperText={error["country"]}
										onBlur={() => {
											handleValidation("country");
										}}
										onChange={(event, val) => {
											if (dataPays !== null) {
												if (val !== null) {
													const selectedCountry = dataPays.pays.find((e) => e.pays === val);
													if (selectedCountry)
														setFormValue({ field: "country", value: selectedCountry });
												} else {
													setFormValue({ field: "country", value: val });
												}
											}
										}}
									/>
								</Grid>
							)}
						</Grid>
					</DialogContent>
					<DialogActions>
						<InexButton
							onClick={onClose}
							variant={"text"}
							textWithGradient={false}
							text={"Annuler"}
							textSx={{
								color: (theme) => theme.palette.secondary.main,
							}}
							sx={{
								width: 100,
								background: "transparent",
								"&:hover": {
									borderColor: (theme) => theme.palette.secondary.main,
									background: (theme) => theme.palette.grey[300],
								},
							}}
						/>
						<InexButton
							onClick={handleValidate}
							disabled={false}
							variant={"text"}
							textWithGradient={false}
							text={"Valider"}
							textSx={{
								color: "white",
							}}
							sx={{
								width: 100,
							}}
						/>
					</DialogActions>
				</>
			) : (
				<CircularProgress />
			)}
		</Dialog>
	);
};

export default AddressCreateModal;
