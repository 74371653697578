import React from "react";
import { Box, IconButton, InputAdornment } from "@mui/material";
import InexTextField from "./InexTextField";
import dropdownIcon from "../resources/images/svg/dropdown_arrow.svg";
import dropdownIconDisabled from "../resources/images/svg/dropdown_arrow_disabled.svg";

const InexNumberInput = ({ disabled, value, onChange, ...props }) => {
	return (
		<InexTextField
			disabled={disabled}
			value={value}
			onChange={(e) => {
				const regex = /^[0-9\b]+$/;
				if (e.target.value === "" || regex.test(e.target.value)) {
					onChange?.(e.target.value);
				}
			}}
			formControlSx={{
				minWidth: "100%",
				width: "100%",
			}}
			endAdornment={
				<InputAdornment position={"end"}>
					<Box
						ml={2}
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							flexDirection: "column",
						}}
					>
						<IconButton
							size={"small"}
							onClick={() => {
								onChange?.(value > 0 ? value + 1 : 1);
							}}
						>
							<img
								src={disabled ? dropdownIconDisabled : dropdownIcon}
								alt={"dropDown"}
								style={{
									width: "10px",
									height: "10px",
									transform: "rotateX(180deg)",
								}}
							/>
						</IconButton>
						<IconButton
							size={"small"}
							onClick={() => {
								onChange?.(value > 1 ? value - 1 : 0);
							}}
						>
							<img
								src={disabled ? dropdownIconDisabled : dropdownIcon}
								alt={"dropDown"}
								style={{
									width: "10px",
									height: "10px",
									filter: "grayscale(1)",
								}}
							/>
						</IconButton>
					</Box>
				</InputAdornment>
			}
			{...props}
		/>
	);
};

export default InexNumberInput;
