import { store } from "../store";
import { encode } from "querystring";
import { api } from "./rtkApi";

export const addressApi = api.injectEndpoints({
	endpoints: (build) => ({
		getUserAdresses: build.query({
			query: () => {
				const state = store.getState();
				let authToken = state.auth.authToken;
				return {
					method: "POST",
					url: `/user/list-adresses`,
					headers: {
						authorization: "Bearer " + authToken,
						"Content-Type": "application/x-www-form-urlencoded",
					},
				};
			},
		}),
		getUserAddress: build.query({
			query: ({ idadresse }) => {
				const state = store.getState();
				let authToken = state.auth.authToken;
				return {
					method: "POST",
					url: `/user/adresse`,
					headers: {
						authorization: "Bearer " + authToken,
						"Content-Type": "application/x-www-form-urlencoded",
					},
					body: encode({
						idadresse,
					}),
				};
			},
		}),
		updateUserAddress: build.mutation({
			query: ({ idadresse, civilite, nom, prenom, adresse, complement1, complement2, ville, cp }) => {
				const state = store.getState();
				let authToken = state.auth.authToken;
				return {
					method: "POST",
					url: `/user/modifier-adresse`,
					headers: {
						authorization: "Bearer " + authToken,
						"Content-Type": "application/x-www-form-urlencoded",
					},
					body: encode({
						idadresse,
						civilite,
						nom,
						prenom,
						adresse,
						complement1,
						complement2,
						ville,
						cp,
					}),
				};
			},
		}),
		addUserAddress: build.mutation({
			query: ({ civilite, nom, prenom, adresse, complement1, complement2, ville, cp, idpays }) => {
				const state = store.getState();
				let authToken = state.auth.authToken;
				return {
					method: "POST",
					url: `/user/ajouter-adresse`,
					headers: {
						authorization: "Bearer " + authToken,
						"Content-Type": "application/x-www-form-urlencoded",
					},
					body: encode({
						idpays,
						civilite,
						nom,
						prenom,
						adresse,
						complement1,
						complement2,
						ville,
						cp,
					}),
				};
			},
		}),
		deleteUserAddress: build.mutation({
			query: ({ idadresse }) => {
				const state = store.getState();
				let authToken = state.auth.authToken;
				return {
					method: "POST",
					url: `/user/supprimer-adresse`,
					headers: {
						authorization: "Bearer " + authToken,
						"Content-Type": "application/x-www-form-urlencoded",
					},
					body: encode({
						idadresse,
					}),
				};
			},
		}),
		getPays: build.query({
			query: () => {
				return {
					method: "GET",
					url: "/common/pays",
				};
			},
		}),
	}),
	overrideExisting: true,
});

export const {
	useGetUserAddressQuery,
	useUpdateUserAddressMutation,
	useAddUserAddressMutation,
	useDeleteUserAddressMutation,
	useGetPaysQuery,
	useLazyGetPaysQuery
} = addressApi;
