import React, { forwardRef, useCallback } from "react";
import { FormControl, IconButton, InputLabel, MenuItem, Select, styled, useTheme } from "@mui/material";
import dropdown_icon from "../resources/images/svg/dropdown_arrow.svg";

const StyledFormControl = styled(FormControl)(({ theme }) => {
	return {
		backgroundColor: "transparent",
		margin: "unset !important",
		fontFamily: "Branding SemiBold",
		border: "1px solid #e2e2e1",
		borderRadius: "8px",
		transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
		"&:hover": {
			borderColor: theme.palette.primary.main,
		},
		"& .MuiSelect-select:focus": {
			borderColor: theme.palette.primary.main,
			backgroundColor: "transparent",
		},
		"& .Mui-focused": {
			backgroundColor: "transparent",
		},
		"& .MuiInputBase-root": {
			backgroundColor: "transparent",
			borderRadius: "8px",
			overflow: "hidden",
			"&::after": {
				bottom: 0,
				borderRadius: 25,
			},
			"&::before": {
				width: 0,
				marginLeft: 4,
				marginRight: 4,
			},
			"&:hover": {
				borderColor: theme.palette.primary.main,
				backgroundColor: "transparent",
			},
			"& .MuiSelect-icon": {
				marginRight: 4,
			},
		},
	};
});

/**
 *
 * @param value
 * @param onChange
 * @param options
 * @param valueExtractor {Function} Take option as param and return an id if null return option
 * @param renderOptions
 * @param label
 * @param sxContainer
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const InexSelect = forwardRef(function InexSelect(
	{ value, onChange, options = [], valueExtractor = null, renderOptions = null, label, sxContainer = {}, ...props },
	ref
) {
	const renderItem = useCallback((item, index) => {
		let value = item;
		if (!!valueExtractor) value = valueExtractor(item);

		return (
			<MenuItem key={index.toString()} value={value}>
				{renderOptions ? renderOptions(item) : item}
			</MenuItem>
		);
	}, []);

	if (!options) return null;

	return (
		<StyledFormControl variant="filled" sx={{ ...sxContainer, m: 1 }} fullWidth>
			<InputLabel id="inex-select-filled-label">{label}</InputLabel>
			<Select
				ref={ref}
				labelId="inex-select-filled-label"
				id="inex-simple-select-filled"
				value={value}
				onChange={onChange}
				IconComponent={(props) => (
					<IconButton aria-label="delete" size="small" {...props}>
						<img src={dropdown_icon} alt={"dropdown_icon"} />
					</IconButton>
				)}
				{...props}
			>
				{options.map((option, index) => renderItem(option, index))}
			</Select>
		</StyledFormControl>
	);
});

export default InexSelect;
