import React, { useCallback, useMemo } from "react";
import AbonnementBoxContent from "./AbonnementBoxContent";
import InexCard from "../../InexCard";
import { Box, Grid, styled, Typography } from "@mui/material";
import InexButton from "../../InexButton";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import AbonnementModal from "./modalEdition/AbonnementModal";
import { useIsMobile } from "../../../hooks/responsive";
import { SUBSCRIPTIONS_TYPES } from "../../../routes/Abonnement";
import moment from "moment/moment";

const BoxTitle = styled(Typography)(({ theme }) => {
	return {
		fontFamily: "Branding Bold",
		color: theme.palette.secondary.main,
		fontSize: 24,
		marginBottom: theme.spacing(2),
	};
});

const BoxSubTitle = styled(Typography)(({ theme }) => {
	return {
		fontFamily: "Branding Medium",
		color: "rgba(24,43,67,0.4)",
		fontSize: 16,
		fontWeight: "600",
	};
});

const AbonnementCard = ({ title, subTitle, subscription, address, subscriptionType, isSub }) => {
	const [open, setOpen] = React.useState(false);
	const isMobile = useIsMobile();

	/*const isSub = React.useMemo(() => {
		if (subscription?.daterenouv && (subscription?.daterenouv < 86400 || moment().isAfter((subscription?.daterenouv - 1) * 1000))) return false;
		return !!subscription?.titre;
	}, [subscription]);*/

	const isSubDie = React.useMemo(() => {
		if (subscription?.daterenouv && subscription?.typerenouv===0 && moment().isAfter((subscription?.daterenouv - 14688000) * 1000)) return false;
		return !!subscription?.titre;
	}, [subscription]);

	const hasPaiementError = useMemo(() => {
		return !!subscription?.error;
	}, [subscription]);

	const redirectToSubscribe = useCallback(() => {
		let aboType = 0;
		switch (subscriptionType) {
			case SUBSCRIPTIONS_TYPES.Mag:
				aboType = 3;
				break;
			case SUBSCRIPTIONS_TYPES.Tv:
				aboType = 2;
				break;
			case SUBSCRIPTIONS_TYPES.Digital:
				aboType = 1;
				break;
		}
		window.open(`https://abonnements.inexplore.com/je-mabonne?selectedAbos=[${aboType}]`, "_blank").focus();
	}, [subscriptionType]);

	return (
		<Grid item xs={12} sm={12} md={6} xl={4}>
			<InexCard
				sx={{
					height: "100%",
					padding: (theme) => theme.spacing(3),
					paddingTop: (theme) => theme.spacing(3),
					paddingBottom: (theme) => theme.spacing(3),
					borderRadius: "8px",
					width: "100%",
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
				}}
			>
				<Box>
					<BoxTitle variant={"h4"} textAlign={"left"}>
						{title}
					</BoxTitle>
					<BoxSubTitle variant={"span"}>{subTitle}</BoxSubTitle>
				</Box>
				<AbonnementBoxContent subscription={subscription} subscriptionType={subscriptionType} />
				{hasPaiementError && (
					<Box sx={{ display: "flex", marginTop: (theme) => theme.spacing(2) }}>
						<ErrorOutlineIcon sx={{ fontSize: 25, color: "#F07892" }} />
						<Typography sx={{ color: "#F07892", marginLeft: (theme) => theme.spacing(1) }}>
							Attention votre mode de paiement n’est plus valide pour cet abonnement.
						</Typography>
					</Box>
				)}
				{isSub ? (
					<InexButton
						onClick={() => setOpen(!open)}
						textSx={{
							color: (theme) => theme.palette.secondary.main,
							textTransform: "none",
							"&:first-letter": {
								textTransform: "none",
							},
						}}
						textWithGradient={!isSub}
						disableBackground={isSub}
						sx={{
							width: "100%",
							background: "transparent",
							"&:hover": {
								borderColor: (theme) => theme.palette.secondary.main,
								background: (theme) => theme.palette.grey[300],
							},
						}}
						text={isSub ? `Gérer ma formule ${title}` : `je m'abonne à ${title}`}
					/>
				) : (
					<InexButton
						onClick={redirectToSubscribe}
						textSx={{
							color: (theme) => theme.palette.secondary.main,
							textTransform: "none",
							"&:first-letter": {
								textTransform: "none",
							},
						}}
						textWithGradient={!isSub}
						disableBackground={isSub}
						sx={{
							width: "100%",
							marginTop: (theme) => theme.spacing(2),
							height: "50px",
						}}
						text={isSub ? `Gérer ma formule ${title}` : `je m'abonne à ${title}`}
					/>
				)}
				{isSub && !isSubDie && subscriptionType === SUBSCRIPTIONS_TYPES.Mag && (
					<InexButton
					onClick={redirectToSubscribe}
					textSx={{
						color: (theme) => theme.palette.secondary.main,
						textTransform: "none",
						"&:first-letter": {
							textTransform: "none",
						},
					}}
					textWithGradient={!!isSub}
					disableBackground={!isSub}
					sx={{
						width: "100%",
						marginTop: (theme) => theme.spacing(2),
						height: "50px",
					}}
					text={`Renouveler ma formule ${title}`}
				/>
			)}
			</InexCard>
			{isSub && (
				<AbonnementModal
					open={open}
					setOpen={setOpen}
					address={address}
					subscriptionType={subscriptionType}
					subscription={subscription}
				/>
			)}
		</Grid>
	);
};

export default AbonnementCard;
