import React, { useCallback } from "react";
import {
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	styled,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import AbonnementFrequency from "./AbonnementFrequency";
import AbonnementPaiementList, { NOT_RENEW_INDEX } from "./AbonnementPaiementList";
import InexButton from "../../../InexButton";
import { useGetCurrentSubscriptionsQuery, useUpdateSubscriptionMutation } from "../../../../store/api/subscriptions";
import { SUBSCRIPTIONS_TYPES } from "../../../../routes/Abonnement";
import { setShowSnackbar, setSnackbarSeverity, setSnackbarText } from "../../../../store/reducers/snackbarSlice";
import { useDispatch } from "react-redux";
import AbonnementAddress from "./AbonnementAddress";
import ModalCancelSubscription from "../modalCancelSubscription/ModalCancelSubscription";

const Title = styled(Typography)(({ theme }) => {
	return {
		fontFamily: "Branding Bold",
		color: theme.palette.secondary.main,
		fontSize: 20,
		marginBottom: theme.spacing(2),
	};
});

const SubTitle = styled(Typography)(({ theme }) => {
	return {
		fontFamily: "Branding SemiBold",
		color: theme.palette.secondary.main,
		fontSize: 16,
		marginBottom: theme.spacing(2),
	};
});

const AbonnementModal = ({ open, setOpen, address, subscriptionType, subscription }) => {
	const [scroll, setScroll] = React.useState("paper");
	const [updateSubscription, { isLoading }] = useUpdateSubscriptionMutation();
	const dispatch = useDispatch();
	const [selectedPaiementMethod, setSelectedPaiementMethod] = React.useState(null);
	const [selectedPaiementFrequency, setSelectedPaiementFrequency] = React.useState(null);
	const { data: currentSub, refetch: refetchCurrentSubscription } = useGetCurrentSubscriptionsQuery({});
	const [selectedAddress, setSelectedAddress] = React.useState("");
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

	const [showModalForCancelSubscription, setShowModalForCancelSubscription] = React.useState(false);

	const setShowSnackBar = () => {
		dispatch(setShowSnackbar(true));
		dispatch(setSnackbarSeverity("success"));
		dispatch(setSnackbarText("Vos informations ont bien été mises à jour."));
	};

	const _updateSubscription = useCallback(
		(cancelText = undefined) => {
			updateSubscription({
				mag: +(subscriptionType === SUBSCRIPTIONS_TYPES.Mag),
				digital: +(subscriptionType === SUBSCRIPTIONS_TYPES.Digital),
				tv: +(subscriptionType === SUBSCRIPTIONS_TYPES.Tv),
				zone: subscriptionType === SUBSCRIPTIONS_TYPES.Mag ? selectedAddress?.zone : address?.zone,
				idadresse:
					subscriptionType === SUBSCRIPTIONS_TYPES.Mag ? selectedAddress?.idadresse : address?.idadresse,
				frequence: selectedPaiementFrequency?.frequence,
				typerenouv: selectedPaiementMethod === -1 ? 0 : selectedPaiementMethod?.idcb ? 2 : 3,
				idcb: selectedPaiementMethod?.idcb,
				idiban: selectedPaiementMethod?.idsepa,
				message: cancelText,
			}).then((data) => {
				refetchCurrentSubscription();
				setShowSnackBar();
				setOpen(false);
			});
		},
		[subscriptionType, address, selectedPaiementMethod, selectedPaiementFrequency, selectedAddress]
	);

	const handleClose = () => {
		setOpen(false);
	};

	const descriptionElementRef = React.useRef(null);
	React.useEffect(() => {
		if (open) {
			const { current: descriptionElement } = descriptionElementRef;
			if (descriptionElement !== null) {
				descriptionElement.focus();
			}
		}
	}, [open]);
	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				scroll={scroll}
				aria-labelledby="scroll-dialog-title"
				aria-describedby="scroll-dialog-description"
				fullWidth
				maxWidth={"xl"}
			>
				<DialogTitle>
					<Title>Mon abonnement {subscription?.titre}</Title>
				</DialogTitle>
				<DialogContent>
					<SubTitle>Gérer les options de votre abonnement</SubTitle>
					<Box sx={{ marginBottom: (theme) => theme.spacing(3) }}>
						<AbonnementFrequency
							subscriptionType={subscriptionType}
							subscription={subscription}
							setSelectedPaiementFrequency={setSelectedPaiementFrequency}
						/>
					</Box>
					{subscriptionType === SUBSCRIPTIONS_TYPES.Mag && (
						<Box sx={{ marginBottom: (theme) => theme.spacing(3) }}>
							<AbonnementAddress
								selectedAddress={selectedAddress}
								setSelectedAddress={setSelectedAddress}
								idadresse={currentSub?.newabos?.idadresse}
							/>
						</Box>
					)}
					<SubTitle>Renouvellement : choisir un moyen de paiement</SubTitle>
					<AbonnementPaiementList
						subscription={subscription}
						setSelectedPaiementMethod={setSelectedPaiementMethod}
						subscriptionType={subscriptionType}
					/>
				</DialogContent>
				<DialogActions>
					<InexButton
						onClick={handleClose}
						text={"Annuler"}
						textSx={{
							color: (theme) => theme.palette.secondary.main,
						}}
						sx={{
							width: 100,
							background: "transparent",
							"&:hover": {
								borderColor: (theme) => theme.palette.secondary.main,
								background: (theme) => theme.palette.grey[300],
							},
						}}
						textWithGradient={false}
						disableBackground
					/>
					<InexButton
						onClick={() => {
							if (selectedPaiementMethod !== NOT_RENEW_INDEX) {
								_updateSubscription();
							} else {
								setShowModalForCancelSubscription(true);
							}
						}}
						text={"Enregistrer les modifications"}
						sx={{ width: "240px" }}
						loading={isLoading}
					/>
				</DialogActions>
			</Dialog>
			<ModalCancelSubscription
				open={showModalForCancelSubscription}
				onClose={(text) => {
					setShowModalForCancelSubscription(false);
					_updateSubscription(text);
				}}
			/>
		</div>
	);
};

export default AbonnementModal;
