import React from "react";
import InexCard from "../../InexCard";
import { Box, Grid, styled, Typography } from "@mui/material";
import { useIsMobile } from "../../../hooks/responsive";
import moment from "moment/moment";

const BoxTitle = styled(Typography)(({ theme }) => {
	return {
		fontFamily: "Branding Bold",
		color: theme.palette.secondary.main,
		fontSize: 20,
		marginBottom: theme.spacing(2),
	};
});

const Title = styled(Typography)(({ theme }) => {
	return {
		color: "rgba(22,42,65,0.4)",
		fontFamily: "Branding Medium",
		fontWeight: "500",
		fontSize: 12,
	};
});

const ItemValue = styled(Typography)(({ theme }) => {
	return {
		color: theme.palette.secondary.main,
		fontFamily: "Branding Bold",
		fontSize: 16,
	};
});

const OldAbonnementsCard = ({ subscription }) => {
	const isMobile = useIsMobile();
	const _dateRenouvellement = React.useMemo(() => {
		if (!subscription?.datepaid) return null;
		moment.locale("fr");
		return moment(subscription.datepaid * 1000).format("DD MMMM YYYY");
	}, [subscription]);

	return (
		<Grid item xs={12} sm={4} md={4} sx={{ minWidth: !isMobile ? 350 : 0 }}>
			<InexCard
				sx={{
					height: "100%",
					padding: (theme) => theme.spacing(3),
					paddingTop: (theme) => theme.spacing(3),
					paddingBottom: (theme) => theme.spacing(3),
					borderRadius: "8px",
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
				}}
			>
				<BoxTitle variant={"h4"} textAlign={"left"}>
					{subscription?.titre}
				</BoxTitle>
				{subscription?.datepaid ? (
					<Box xs={6}>
						<Title variant={"span"}>Dernier paiement</Title>
						<ItemValue>Le {_dateRenouvellement}</ItemValue>
						{subscription?.action === 1 ? 
						(<a href={subscription?.lien} target="_blank">Gérer ma formule</a>) 
						: (<></>)
						}
					</Box>
				) : null}
			</InexCard>
		</Grid>
	);
};

export default OldAbonnementsCard;
