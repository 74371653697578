import React from "react";
import { Box, Typography, Grid, styled, Checkbox, FormControlLabel, FormGroup, Collapse } from "@mui/material";
import InexTextField from "../InexTextField";
import { Controller } from "react-hook-form";
import InexSelect from "../InexSelect";
import { useIsLoggedIn } from "../../hooks/auth";

const ErrorMessage = styled(Typography)(({ theme }) => {
	return {
		color: "red",
		fontSize: 14,
		marginLeft: 4,
	};
});
const ContactUserForm = ({ subjects, control, handleSubmit, errors, watch, resetField }) => {
	const isLoggedIn = useIsLoggedIn();

	const rappel = watch("rappel");
	const subject = watch("subject");

	React.useEffect(() => {
		if (subject === 1) {
			resetField("rappel");
		}
	}, [subject, resetField]);

	return (
		<Grid item container spacing={2}>
			{isLoggedIn === false && (
				<>
					<Grid item container xs={12} spacing={2}>
						<Grid item xs={12} md={4} sm={12}>
							<Controller
								name="firstName"
								rules={{ required: true }}
								control={control}
								render={({ field }) => (
									<InexTextField
										label={"Prénom*"}
										formControlSx={{
											width: "100%",
										}}
										{...field}
									/>
								)}
							/>
							{errors.firstName?.type === "required" && (
								<ErrorMessage role="alert">Le prénom est requis</ErrorMessage>
							)}
						</Grid>
						<Grid item xs={12} md={4} sm={12}>
							<Controller
								name="lastName"
								rules={{ required: true }}
								control={control}
								render={({ field }) => (
									<InexTextField
										label={"Nom*"}
										formControlSx={{
											width: "100%",
										}}
										{...field}
									/>
								)}
							/>
							{errors.lastName?.type === "required" && (
								<ErrorMessage role="alert">Le nom est requis</ErrorMessage>
							)}
						</Grid>
					</Grid>
					<Grid item container xs={12} spacing={2}>
						<Grid item xs={12} md={4} sm={12}>
							<Controller
								name="email"
								control={control}
								rules={{ required: true, pattern: /^([a-z0-9_\.-]+\@[\da-z\.-]+\.[a-z\.]{2,6})$/ }}
								render={({ field }) => (
									<InexTextField
										label={"Email*"}
										formControlSx={{
											width: "100%",
										}}
										{...field}
									/>
								)}
							/>
							{errors.email?.type === "required" && (
								<ErrorMessage role="alert">L'adresse email est requise</ErrorMessage>
							)}
							{errors.email?.type === "pattern" && (
								<ErrorMessage role="alert">L'adresse email n'est pas valide</ErrorMessage>
							)}
						</Grid>
						<Grid item xs={12} md={4} sm={12}>
							<Controller
								name="phone"
								control={control}
								rules={{ pattern: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/ }}
								render={({ field }) => (
									<InexTextField
										label={"Téléphone portable*"}
										formControlSx={{
											width: "100%",
										}}
										{...field}
									/>
								)}
							/>
							{errors.phone?.type === "pattern" && (
								<ErrorMessage role="alert">Le numéro n'est pas valide</ErrorMessage>
							)}
						</Grid>
					</Grid>
				</>
			)}
			<Grid item container xs={12} spacing={2}>
				<Grid item xs={12} md={6} sm={12}>
					<Controller
						name="subject"
						rules={{ required: true }}
						control={control}
						render={({ field }) => {
							return (
								<InexSelect
									value={field.value}
									options={subjects}
									label={"Objet de votre message"}
									sxContainer={{ backgroundColor: "#FFFFFF" }}
									renderOptions={(option) => (
										<Box sx={{ paddingY: 1 }}>
											<Typography>{option.titre}</Typography>
										</Box>
									)}
									valueExtractor={(item) => {
										return item?.idsujet || 0;
									}}
									onChange={field.onChange}
								/>
							);
						}}
					/>
					{errors.subject?.type === "required" && (
						<ErrorMessage role="alert">L'objet est requis</ErrorMessage>
					)}
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Controller
					name="message"
					rules={{ required: true }}
					control={control}
					render={({ field }) => (
						<InexTextField
							label={"Votre message"}
							formControlSx={{
								width: "100%",
							}}
							multiline
							rows={8}
							{...field}
						/>
					)}
				/>
				{errors.message?.type === "required" && <ErrorMessage role="alert">Le message est requis</ErrorMessage>}
			</Grid>
			<Grid item xs={12}>
				<Collapse in={subject !== 1}>
					<FormGroup>
						<Controller
							name="rappel"
							control={control}
							render={({ field }) => (
								<FormControlLabel
									color="default"
									control={<Checkbox checked={rappel} />}
									label={"Je souhaite être rappelé à ce numéro"}
									{...field}
									value={rappel}
								/>
							)}
						/>
					</FormGroup>
				</Collapse>
			</Grid>
			<Grid item xs={12} md={4} sm={12}>
				<Controller
					name="telrappel"
					rules={{ required: !!rappel, pattern: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/ }}
					control={control}
					render={({ field }) => (
						<Collapse in={!!rappel}>
							<InexTextField
								label={"Numéro de téléphone*"}
								formControlSx={{
									width: "100%",
								}}
								{...field}
							/>
							<Typography fontFamily={"Branding SemiBold"} fontSize={14}>
								Pour information, vous serez rappelé seulement si votre message concerne : un problème
								technique, votre abonnement, votre commande ou votre réservation.
							</Typography>
						</Collapse>
					)}
				/>
				{errors.telrappel?.type === "required" && !!rappel && (
					<ErrorMessage role="alert">Le numéro de téléphone est requis</ErrorMessage>
				)}
				{errors.telrappel?.type === "pattern" && !!rappel && (
					<ErrorMessage role="alert">Le numéro n'est pas valide</ErrorMessage>
				)}
			</Grid>
		</Grid>
	);
};

export default ContactUserForm;
