import React from "react";
import { Collapse, Grid, IconButton, Typography } from "@mui/material";
import { useIsMobile } from "../../hooks/responsive";
import TipArgumentCard from "./TipArgumentCard";

import dropdownIcon from "../../resources/images/svg/dropdown_arrow.svg";
import ethicalIcon from "../../resources/images/svg/tipChoice/EhticalIcon.png";
import trustIcon from "../../resources/images/svg/tipChoice/TrustIcon.png";
import ecosystemIcon from "../../resources/images/svg/tipChoice/EcosystemIcon.png";
import loveIcon from "../../resources/images/svg/tipChoice/LoveIcon.png";
import generalInterestIcon from "../../resources/images/svg/tipChoice/GeneralInterestIcon.png";

const argumentsList = [
	{
		icon: ethicalIcon,
		title: "Éthique",
		subTitle:
			"Nous vous garantissons une expérience sans publicité invasive. La sécurité et la confidentialité de vos données personnelles sont nos priorités.",
	},
	{
		icon: trustIcon,
		title: "Label de confiance",
		subTitle:
			"Pour que les informations que nous partageons participent à l’éveil du monde, nos investigations journalistiques sont soumises à un regard critique très exigeant.",
	},
	{
		icon: ecosystemIcon,
		title: "À l’image d’un écosystème",
		subTitle:
			"Notre planète qui est un trésor à protéger est notre source d’inspiration. L’organisation de notre entreprise est ancrée dans ce concept de biosphère.",
	},
	{
		icon: loveIcon,
		title: "Tournée vers les abonnés",
		subTitle:
			"Pour permettre au plus grand nombre d’accéder à nos contenus, nous nous engageons à l’excellence dans notre travail ainsi qu’au maintien de prix abordables et justes.",
	},
	{
		icon: generalInterestIcon,
		title: "D’intérêt général",
		subTitle:
			"Lorsque les expériences se veulent bouleversantes, nous proposons à ceux qui les vivent une écoute et des conseils gratuits prodigués par notre réseau de professionnels de santé.",
	},
];

const TipArguments = () => {
	const isMobile = useIsMobile();

	return (
		<>
			<Grid
				item
				container
				wrap={"nowrap"}
				flexDirection={"row"}
				justifyContent={"space-between"}
				alignItems={"center"}
			>
				<Typography
					variant={"h5"}
					textAlign={"left"}
					sx={{
						fontFamily: "Branding Bold",
						fontSize: 16,
					}}
				>
					{"Pourquoi faire un don à l'INREES ?"}
				</Typography>
			</Grid>
			<Grid item>
				<Typography
					textAlign={"left"}
					color={"grey.500"}
					sx={{
						fontFamily: "Branding SemiBold",
						fontSize: 16,
						marginTop: (theme) => theme.spacing(2),
					}}
				>
					{
						"L’INREES, qui édite Inexploré, est une entreprise qui prône le respect du vivant et de l ’être humain et qui s’engage en faveur de l’évolution des consciences. L ’écoute, l’ouverture d’esprit et la recherche perpétuelle de la qualité sont les valeurs principales que nous avons à cœur d’incarner dans notre quotidien. La bienveillance est, quant à elle, notre inspiration, tant pour nos clients, nos partenaires que pour nos collaborateurs. Figure de ces fondements déontologiques, la démarche de l ’INREES et d’Inexploré se veut :"
					}
				</Typography>
			</Grid>
			<Grid item container flexDirection={"row"} wrap={"wrap"} spacing={2} mt={2}>
				{argumentsList.map((element, index) => {
					return (
						<Grid item key={index} xs={12} sm={6} lg={4}>
							<TipArgumentCard
								icon={
									<img
										src={element.icon}
										alt={"icon"}
										style={{
											objectFit: "cover",
											width: "100%",
											height: "100%",
										}}
									/>
								}
								title={element.title}
								subTitle={element.subTitle}
							/>
						</Grid>
					);
				})}
			</Grid>
		</>
	);
};

export default TipArguments;
