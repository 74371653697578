import React from "react";
import { Dialog, DialogActions, DialogTitle } from "@mui/material";
import InexButton from "../InexButton";

const DeleteConfirmDialog = ({
	onClose,
	title = "Êtes vous sûr(e) de vouloir supprimer cette adresse ?",
	...props
}) => {
	return (
		<Dialog onClose={onClose} {...props}>
			<DialogTitle color={"secondary"}>{title}</DialogTitle>
			<DialogActions>
				<InexButton
					onClick={() => {
						onClose(false);
					}}
					variant={"text"}
					textWithGradient={false}
					text={"Annuler"}
					textSx={{
						color: (theme) => theme.palette.secondary.main,
					}}
					sx={{
						width: 100,
						background: "transparent",
						"&:hover": {
							borderColor: (theme) => theme.palette.secondary.main,
							background: (theme) => theme.palette.grey[300],
						},
					}}
				/>
				<InexButton
					onClick={() => {
						onClose(true);
					}}
					variant={"text"}
					textWithGradient={false}
					text={"Oui"}
					textSx={{
						color: "white",
					}}
					sx={{
						width: 100,
					}}
				/>
			</DialogActions>
		</Dialog>
	);
};

export default DeleteConfirmDialog;
