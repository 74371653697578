import React from "react";
import { Box, Fade, styled, Typography, useTheme } from "@mui/material";
import { useIsMobile } from "../../hooks/responsive";
import InexCard from "../InexCard";
import InexButton from "../InexButton";
import { useSetActionQuestionMutation } from "../../store/api/faq";
import { setShowSnackbar, setSnackbarSeverity, setSnackbarText } from "../../store/reducers/snackbarSlice";
import { useDispatch } from "react-redux";

const Title = styled(Typography)(({ theme, selected }) => {
	return {
		fontFamily: "Branding Bold",
		fontSize: 16,
	};
});
const Content = styled(Typography)(({ theme, selected }) => {
	return {
		fontFamily: "Branding SemiBold",
		fontSize: 16,
		color: "#717D8C",
		a: {
			color: "#F07892",
		},
	};
});

const Question = styled(Typography)(({ theme, selected }) => {
	return {
		fontFamily: "Branding SemiBold",
		fontSize: 16,
		color: "#162A41",
	};
});

const FaqAnswer = ({ answer }) => {
	const [setAction] = useSetActionQuestionMutation();
	const isMobile = useIsMobile();
	const theme = useTheme();
	const dispatch = useDispatch();
	const [hasAnswered, setHasAnswered] = React.useState(false);

	const _setAction = (action) => {
		setAction({ id: answer?.idquestion, action }).then((res) => {
			dispatch(setShowSnackbar(true));
			dispatch(setSnackbarSeverity("success"));
			dispatch(setSnackbarText("Votre retour est pris en compte"));
			setHasAnswered(true);
		});
	};

	return (
		<InexCard
			sx={{
				display: "flex",
				flexDirection: "row",
				padding: (theme) => theme.spacing(isMobile ? 1 : 3),
				paddingTop: theme.spacing(isMobile ? 1 : 2),
				paddingBottom: theme.spacing(isMobile ? 1 : 2),
				alignItems: "center",
				borderRadius: "8px !important",
				boxShadow: "box-shadow: 0 2px 29px 0 rgba(22,42,65,0.2)",
				justifyContent: "space-between",
			}}
		>
			<Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
				<Title dangerouslySetInnerHTML={{ __html: answer?.titre }} />
				<Content dangerouslySetInnerHTML={{ __html: answer?.reponse }} />
				<Box sx={{ marginTop: 5 }}>
					{!hasAnswered ? (
						<Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
							<Question>Cet article vous a-t-il été utile ?</Question>
							<InexButton
								text={"Oui"}
								onClick={() => _setAction(1)}
								textSx={{
									color: (theme) => theme.palette.secondary.main,
								}}
								sx={{
									width: 70,
									background: "transparent",
									"&:hover": {
										borderColor: (theme) => theme.palette.secondary.main,
										background: (theme) => theme.palette.grey[300],
									},
								}}
								textWithGradient={false}
								disableBackground
							/>
							<InexButton
								text={"Non"}
								onClick={() => _setAction(2)}
								textSx={{
									color: (theme) => theme.palette.secondary.main,
								}}
								sx={{
									width: 70,
									background: "transparent",
									"&:hover": {
										borderColor: (theme) => theme.palette.secondary.main,
										background: (theme) => theme.palette.grey[300],
									},
								}}
								textWithGradient={false}
								disableBackground
							/>
						</Box>
					) : (
						<Fade in={true} timeout={600}>
							<Box sx={{ display: "flex", alignItem: "center" }}>
								<Typography fontFamily={"Branding SemiBold"} fontSize={17}>
									Merci ! Nous sommes ravis d'avoir pu vous aider !
								</Typography>
							</Box>
						</Fade>
					)}
				</Box>
			</Box>
		</InexCard>
	);
};

export default FaqAnswer;
