import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import "dayjs/locale/fr";
import { FormControl, styled } from "@mui/material";

const StyledDatePicker = styled(DatePicker)(({ theme }) => {
	return {
		backgroundColor: "white",
		margin: "unset !important",
		fontFamily: "Branding SemiBold",
		borderRadius: "8px",
		"& .MuiInputLabel-root ": {
			backgroundColor: "white",
			padding: 5,
			borderRadius: "8px",
		},
		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: "#e2e2e1",
			borderRadius: 8,
			"&:hover": {
				borderColor: "red",
			},
		},
		"& .MuiInputBase-root": {
			borderRadius: "8px",
			borderColor: "red",
			borderWidth: 1,
			"&:hover": {
				borderColor: "red",
				backgroundColor: "transparent",
			},
		},
		"& .MuiOutlinedInput-root:hover": {
			"& .MuiOutlinedInput-notchedOutline": {
				transition: theme.transitions.create(["border-color", "box-shadow"]),
			},
			"&:hover": {
				"& .MuiOutlinedInput-notchedOutline": {
					borderColor: theme.palette.primary.main,
				},
			},
		},
	};
});

const InexDatePicker = ({ value, onChange, ...rest }) => {
	return (
		<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"fr"}>
			<StyledDatePicker label="Date de prélévement" value={value} onChange={onChange} {...rest} />
		</LocalizationProvider>
	);
};

export default InexDatePicker;
