import React from "react";
import { ContentContainer, Title } from "./Abonnement";
import withAuth from "../HOC/withAuth";
import { useAuthToken } from "../hooks/auth";
import { useGetCurrentSubscriptionsQuery } from "../store/api/subscriptions";
import { Grid, Skeleton, Typography } from "@mui/material";
import DonationInfo from "../components/donation/DonationInfo";
import UpdateDonation from "../components/donation/UpdateDonation";
import CreateDonation from "../components/donation/CreateDonation";
import useTabTitle from "../hooks/useTabTitle";

const Donations = () => {
	useTabTitle({ customTitle: "Mes donations" });

	const authToken = useAuthToken();
	const {
		data: { newabos, oldabos } = {},
		isLoading,
		isFetching,
		refetch,
	} = useGetCurrentSubscriptionsQuery({}, { skip: !authToken });

	const currentDonation = React.useMemo(() => {
		return newabos?.don;
	}, [newabos]);

	return (
		<ContentContainer container item className={"content-background"}>
			<Grid
				container
				item
				xs={12}
				sx={{
					marginBottom: (theme) => theme.spacing(2),
				}}
				spacing={3}
			>
				{!isLoading && !isFetching && !!newabos ? (
					<React.Fragment>
						<DonationInfo currentDonation={currentDonation} refetch={refetch} />
						{/*{!!currentDonation ? (*/}
						{/*	<UpdateDonation currentDonation={currentDonation} refetch={refetch} />*/}
						{/*) : (*/}
						{/*	<CreateDonation refetch={refetch} />*/}
						{/*)}*/}
					</React.Fragment>
				) : (
					<Grid item container gap={2} xs={12}>
						<React.Fragment>
							<Grid item xs={12}>
								<Skeleton
									width={400}
									height={"30px"}
									sx={{
										backgroundColor: "white",
										boxShadow: (theme) => theme.shadows[25],
									}}
									variant="rectangular"
									animation={"pulse"}
								/>
							</Grid>
							<Grid item xs={12}>
								<Skeleton
									width={"100%"}
									height={"40px"}
									sx={{
										backgroundColor: "white",
										boxShadow: (theme) => theme.shadows[25],
									}}
									variant="rectangular"
									animation={"pulse"}
								/>
							</Grid>
							<Grid item container xs={12} spacing={2}>
								<Grid item xs={12} md={6}>
									<Skeleton
										width={"100%"}
										height={"50px"}
										sx={{
											backgroundColor: "white",
											boxShadow: (theme) => theme.shadows[25],
										}}
										variant="rectangular"
										animation={"pulse"}
									/>
								</Grid>
								<Grid item xs={12} md={6}>
									<Skeleton
										width={"100%"}
										height={"50px"}
										sx={{
											backgroundColor: "white",
											boxShadow: (theme) => theme.shadows[25],
										}}
										variant="rectangular"
										animation={"pulse"}
									/>
								</Grid>
							</Grid>
						</React.Fragment>
					</Grid>
				)}
			</Grid>
		</ContentContainer>
	);
};

export default withAuth(Donations);
