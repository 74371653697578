import React from "react";
import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import InvoiceHeader from "./InvoiceHeader";
import GradientLine from "./GradientLine";
import InvoiceContent from "./InvoiceContent";
import InvoiceFooter from "./InvoiceFooter";

export const mainStyles = StyleSheet.create({
	header: {
		marginBottom: 30,
	},
	body: {
		flexGrow: 1,
		margin: "0 40 0 40",
		display: "flex",
		flexDirection: "column",
	},
	text: {
		fontFamily: "Branding",
		textAlign: "justify",
		color: "#182B43",
		fontSize: 14,
	},
	textBold: {
		fontWeight: "bold",
	},
	textSemiBold: {
		fontWeight: "semibold",
	},
	textBig: {
		fontSize: 16,
	},
	textSmall: {
		fontSize: 10,
	},
	textLight: {
		color: "rgb(44, 165, 214)",
	},
	textPurple: {
		color: "#8F6C9F",
	},
	container: {
		background: "yellow",
		width: "100px",
		height: "300px",
	},
	gradientLine: {
		minHeight: 3,
		borderRadius: 1.5,
		width: "100%",
		marginTop: 30,
		backgroundColor: "red",
		// background:
		// 	"linear-gradient(90deg, rgba(108,52,144,1) 0%, rgba(73,74,146,1) 34%, rgba(46,107,164,1) 67%, rgba(85,168,217,1) 100%)",
	},
	pageNumber: {
		fontSize: 10,
		textAlign: "center",
		color: "grey",
		marginTop: 10,
		marginBottom: 10,
	},
});

const Invoice = ({ order, address }) => {
	return (
		<Document
			title={`Facture Inexploré${order.reference ? ` ${order.reference.toUpperCase()}` : ""}`}
			author={"Inexploré"}
			subject={"Facture Inexploré"}
			creator={"Inexploré (INREES)"}
			keywords={"facture;inexploré"}
			language={"French"}
		>
			<Page size="A4">
				<View fixed style={{ ...mainStyles.header }} />
				<View style={mainStyles.body}>
					<InvoiceHeader order={order} address={address} />
					<Text
						style={{
							...mainStyles.text,
							...mainStyles.textBold,
							...mainStyles.textBig,
							...mainStyles.textPurple,
							marginTop: 30,
							marginLeft: 3,
						}}
					>
						Détail de la facture :
					</Text>
					<GradientLine
						style={{
							marginTop: 5,
						}}
					/>
					<InvoiceContent order={order} />
					<InvoiceFooter order={order} />
				</View>
				<Text
					style={mainStyles.pageNumber}
					render={({ pageNumber, totalPages }) => (totalPages > 1 ? `${pageNumber} / ${totalPages}` : "")}
					fixed
				/>
			</Page>
		</Document>
	);
};

export default Invoice;
