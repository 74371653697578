import { api } from "./rtkApi";
import { encode } from "querystring";

export const faqApi = api.injectEndpoints({
	endpoints: (build) => ({
		getFaqCategories: build.query({
			query: () => {
				return {
					method: "GET",
					url: `/common/faq-list-categories`,
				};
			},
			transformResponse(baseQueryReturnValue, meta, arg) {
				return baseQueryReturnValue?.categories;
			},
		}),
		getCategoryQuestions: build.query({
			query: ({ id }) => {
				return {
					method: "POST",
					url: `/common/faq-questions-category`,
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
					},
					body: encode({
						idcat: id,
					}),
				};
			},
			transformResponse(baseQueryReturnValue, meta, arg) {
				return baseQueryReturnValue;
			},
		}),
		getFaqAnswer: build.query({
			query: ({ id }) => {
				return {
					method: "POST",
					url: `/common/faq-question`,
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
					},
					body: encode({
						idquestion: id,
					}),
				};
			},
			transformResponse(baseQueryReturnValue, meta, arg) {
				return baseQueryReturnValue?.question;
			},
		}),
		setActionQuestion: build.mutation({
			query: ({ id, action }) => {
				return {
					method: "POST",
					url: `/common/action-question`,
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
					},
					body: encode({
						idquestion: id,
						action,
					}),
				};
			},
			transformResponse(baseQueryReturnValue, meta, arg) {
				return baseQueryReturnValue?.question;
			},
		}),
	}),
	overrideExisting: true,
});

export const {
	useGetFaqCategoriesQuery,
	useLazyGetCategoryQuestionsQuery,
	useLazyGetFaqAnswerQuery,
	useSetActionQuestionMutation,
} = faqApi;
