import { api } from "./api/rtkApi";
import { combineReducers } from "@reduxjs/toolkit";
import AuthSlice from "./reducers/authSlice";
import SnackbarSlice from "./reducers/snackbarSlice";

export default combineReducers({
	[api.reducerPath]: api.reducer,
	auth: AuthSlice,
	snackbar: SnackbarSlice,
});
