import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getShowSnackbar, setShowSnackbar } from "../../store/reducers/authSlice";
import { Alert, Snackbar } from "@mui/material";

const SuccessfulConnexionSnackbar = () => {
  const showSnackbar = useSelector(getShowSnackbar);

  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setShowSnackbar(false));
  };

  return (
      <Snackbar
          open={Boolean(showSnackbar)}
          autoHideDuration={2000}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Connexion réussie.
        </Alert>
      </Snackbar>
  );
};

export default SuccessfulConnexionSnackbar;
