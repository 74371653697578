import { api } from "./rtkApi";
import { encode } from "querystring";

export const contactApi = api.injectEndpoints({
	endpoints: (build) => ({
		getContactSubjects: build.query({
			query: () => {
				return {
					method: "GET",
					url: `/common/sujets`,
				};
			},
			transformResponse(baseQueryReturnValue, meta, arg) {
				return baseQueryReturnValue?.sujets;
			},
		}),
		sendContactMessage: build.mutation({
			query: ({
				idinexplore,
				email,
				prenom,
				nom,
				portable,
				idsujet,
				message,
				rappel,
				telrappel,
				RecaptchaToken,
			}) => {
				return {
					method: "POST",
					url: `/common/contact`,
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
					},
					body: encode({
						idinexplore,
						email,
						prenom,
						nom,
						portable,
						idsujet,
						message,
						rappel,
						telrappel,
						RecaptchaToken,
					}),
				};
			},
			transformResponse(baseQueryReturnValue, meta, arg) {
				return baseQueryReturnValue?.question;
			},
		}),
	}),
	overrideExisting: true,
});

export const { useGetContactSubjectsQuery, useSendContactMessageMutation } = contactApi;
