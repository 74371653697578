import React, { forwardRef } from "react";
import { FilledInput, FormControl, InputLabel, styled, Typography } from "@mui/material";

const StyledTextField = styled(FilledInput, {
	shouldForwardProp: (prop) => !["InputLabelProps"].includes(prop),
})(({ theme, disabled, success = false, error = false }) => {
	let borderBaseColor = "#e2e2e1";
	if (success === true) borderBaseColor = theme.palette.success.main;
	if (error === true) borderBaseColor = theme.palette.error.main;
	return {
		border: `1px dashed ${disabled !== true ? borderBaseColor : theme.palette.grey[300]}`,
		borderRadius: "8px",
		fontFamily: "Branding SemiBold",

		overflow: "hidden",
		transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
		boxShadow: "none",

		"&:hover": {
			border: `1px solid ${theme.palette.primary.main}`,
			backgroundColor: "transparent",
		},
		"&:hover:before": {
			borderBottom: "none !important",
		},
		"&:focus": {
			border: `1px solid ${theme.palette.primary.main}`,
		},
		"&:before": {
			display: "none",
		},
		color: disabled !== true ? theme.palette.secondary.main : theme.palette.grey[300],
		".MuiTextField-inputRoot:hover > fieldset": {
			borderColor: theme.palette.primary.main,
		},
		".MuiInputBase-root": {
			color: disabled !== true ? theme.palette.secondary.main : theme.palette.grey[300],
			backgroundColor: "transparent",
		},
		"&.MuiInputBase-root": {
			border: `1px solid ${disabled !== true ? borderBaseColor : theme.palette.grey[300]}`,
			color: disabled !== true ? theme.palette.secondary.main : theme.palette.grey[300],
			backgroundColor: "white",
		},
		"&.MuiInputBase-root:hover": {
			border: `1px solid ${theme.palette.primary.main}`,
		},
		"&.MuiInputBase-root:focus": {
			border: `1px solid ${theme.palette.primary.main}`,
		},
		".MuiInputBase-root:hover": {
			backgroundColor: "transparent",
		},
		".MuiInputBase-root::before": {
			display: "none",
		},
		".MuiInputBase-root:hover > label": {
			transform: "translate(14px, -5px) scale(0.75)",
		},
		"& + .MuiInputBase-popper .MuiAutocomplete-option": {
			color: theme.palette.secondary.main,
		},
	};
});

const InexTextField = forwardRef(function InexTextField(
	{ disabled = false, label = "Label", value, formControlSx, helperText, error = false, success = false, ...props },
	ref
) {
	return (
		<FormControl
			sx={{
				border: "none",
				"&:hover": {
					border: "none",
				},
				...formControlSx,
			}}
			variant="outlined"
		>
			<InputLabel
				variant={"filled"}
				htmlFor="outlined-adornment-password"
				disabled={disabled}
				sx={{
					color: (theme) =>
						`${success === true ? theme.palette.success.main : theme.palette.secondary.main}${
							value === "" || !value ? "99" : ""
						}`,
					fontSize: "0.9rem",
				}}
			>
				{label}
			</InputLabel>
			<StyledTextField
				ref={ref}
				value={value}
				disabled={disabled}
				label={label}
				error={error}
				success={success}
				{...props}
			/>
			{helperText && (
				<Typography variant={"caption"} color={error ? "error" : "secondary"} pl={1}>
					{helperText}
				</Typography>
			)}
		</FormControl>
	);
});

export default InexTextField;
