import React from "react";
import { useSelector } from "react-redux";
import { getInreesId, getUser } from "../../store/reducers/authSlice";
import { useAuthToken } from "../../hooks/auth";
import { useGetUserAddressesQuery, useLazyDeleteAddressQuery } from "../../store/api/user";
import { Grid, Skeleton } from "@mui/material";
import AddressCard from "./AddressCard";
import AddressCreateModal from "./AddressCreateModal";
import DeleteConfirmDialog from "./DeleteConfirmDialog";
import InexButton from "../InexButton";
import { useGetCurrentSubscriptionsQuery } from "../../store/api/subscriptions";

const AddressesList = () => {
	const inreesId = useSelector(getInreesId);
	const authToken = useAuthToken();
	const user = useSelector(getUser);

	const [subAddress, setSubAddress] = React.useState(null);
	const { data: infos, isFetching: isFetchingAbos } = useGetCurrentSubscriptionsQuery(
		{},
		{
			skip: !user,
		}
	);

	React.useEffect(() => {
		if (infos?.newabos) {
			setSubAddress(infos.newabos.idadresse);
		}
	}, [infos]);

	const [addresses, setAddresses] = React.useState(null);
	const { data, isFetching, refetch } = useGetUserAddressesQuery(
		{
			idinexplore: inreesId,
			authToken,
		},
		{
			skip: !inreesId || !authToken || !user,
		}
	);

	React.useEffect(() => {
		if (data) {
			setAddresses(data.infos);
		}
	}, [data]);

	const [deleteAddress] = useLazyDeleteAddressQuery();

	const [openModal, setOpenModal] = React.useState(false);

	const handleClose = (withRefresh = false) => {
		setOpenModal(false);
		withRefresh === true && handleRefetch();
	};

	const handleRefetch = () => {
		refetch();
	};

	const [addressToDelete, setAddressToDelete] = React.useState(null);

	return (
		<>
			<Grid container spacing={2} mt={0} direction={"row"} wrap={"wrap"}>
				{isFetching === true || addresses === null || isFetchingAbos === true ? (
					<>
						<Grid item xs={12} sm={6} md={6} lg={4}>
							<Skeleton
								width={"100%"}
								height={"250px"}
								sx={{ backgroundColor: "white", boxShadow: (theme) => theme.shadows[25] }}
								variant="rectangular"
								animation={"pulse"}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={4}>
							<Skeleton
								width={"100%"}
								height={"250px"}
								sx={{ backgroundColor: "white", boxShadow: (theme) => theme.shadows[25] }}
								variant="rectangular"
								animation={"pulse"}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={4}>
							<Skeleton
								width={"100%"}
								height={"250px"}
								sx={{ backgroundColor: "white", boxShadow: (theme) => theme.shadows[25] }}
								variant="rectangular"
								animation={"pulse"}
							/>
						</Grid>
					</>
				) : (
					<>
						{addresses?.map((element, index) => {
							return (
								<Grid key={index} item xs={12} sm={12} md={6} lg={6} xl={4}>
									<AddressCard
										address={element}
										selected={element.idadresse === subAddress}
										canDelete={addresses.length > 1}
										onEdit={() => {
											setOpenModal(element);
										}}
										onDelete={() => {
											setAddressToDelete(element);
										}}
									/>
								</Grid>
							);
						})}
						<Grid item xs={12}>
							{/*<InexCard*/}
							{/*	sx={{*/}
							{/*		height: "100%",*/}
							{/*		display: "flex",*/}
							{/*		alignItems: "center",*/}
							{/*		justifyContent: "center",*/}
							{/*		flexDirection: "column",*/}
							{/*		boxShadow: "none",*/}
							{/*		borderRadius: (theme) => theme.spacing(1),*/}
							{/*		border: (theme) => `1px solid ${theme.palette.grey[300]}`,*/}
							{/*	}}*/}
							{/*	onClick={() => {*/}
							{/*		setOpenModal(true);*/}
							{/*	}}*/}
							{/*>*/}
							{/*	<Typography*/}
							{/*		color={"secondary"}*/}
							{/*		fontSize={20}*/}
							{/*		textAlign={"center"}*/}
							{/*		sx={{*/}
							{/*			userSelect: "none",*/}
							{/*		}}*/}
							{/*	>*/}
							{/*		Ajouter une nouvelle adresse*/}
							{/*	</Typography>*/}
							{/*	<IconButton*/}
							{/*		onClick={(e) => {*/}
							{/*			e.preventDefault();*/}
							{/*			e.stopPropagation();*/}
							{/*			setOpenModal(true);*/}
							{/*		}}*/}
							{/*		size={"large"}*/}
							{/*		sx={{*/}
							{/*			marginTop: 3,*/}
							{/*			width: 75,*/}
							{/*			height: 75,*/}
							{/*			color: (theme) => theme.palette.secondary.main,*/}
							{/*			backgroundColor: (theme) => theme.palette.grey[300],*/}
							{/*		}}*/}
							{/*	>*/}
							{/*		+*/}
							{/*	</IconButton>*/}
							{/*</InexCard>*/}

							<Grid item xs={12} md={4} lg={3}>
								<InexButton
									onClick={() => {
										setOpenModal(true);
									}}
									text={"Ajouter une adresse"}
									variant={"text"}
									sx={{
										padding: (theme) => theme.spacing(2),
										width: "100%",
										height: "50px",
									}}
								/>
							</Grid>
						</Grid>
					</>
				)}
			</Grid>
			<AddressCreateModal
				open={!!openModal}
				address={typeof openModal === "object" ? openModal : undefined}
				onClose={(withRefresh) => {
					handleClose(withRefresh);
				}}
			/>
			<DeleteConfirmDialog
				open={!!addressToDelete}
				onClose={(confirm = false) => {
					if (confirm === true) {
						deleteAddress({
							authToken,
							idinexplore: inreesId,
							address: addressToDelete,
						}).then(() => {
							handleRefetch();
						});
					}
					setAddressToDelete(null);
				}}
			/>
		</>
	);
};

export default AddressesList;
