import React from "react";
import { useGetUniversQuery } from "../../store/api/app";
import { Box, Fade, styled, Typography } from "@mui/material";
import earth from "../../resources/images/svg/earth.svg";
import chevron_down from "../../resources/images/svg/dropdown_arrow.svg";
import UniversesList from "./UniversesList";

const BoxUniversContainer = styled(Box)(({ theme }) => {
	return {
		height: 50,
		width: 190,
		background: "linear-gradient(137.52deg, #F5C84D 0%, #F8D667 46.62%, #FBE684 100%)",
		borderRadius: 24,
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		paddingLeft: 10,
		paddingRight: 10,
		transition: theme.transitions.create(["box-shadow"]),
		cursor: "pointer",
		position: "relative",
		"&.isActive": {
			boxShadow: "0 0 15px 0 #EE7A91",
			"& .univers-chevron": {
				transform: "rotate(180deg)",
			},
		},
		"& .univers-chevron": {
			transition: theme.transitions.create(["transform"]),
		},
	};
});

const LogoContainer = styled(Box)(({ theme }) => {
	return {
		width: 30,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	};
});

const EarthStyle = styled("img")(({ theme }) => {
	return {
		width: 22,
	};
});

const ChevronStyle = styled("img")(({ theme }) => {
	return {
		width: 16,
	};
});

const BoxUnivers = styled(Box)(({ theme }) => {
	return {
		position: "absolute",
		right: 0,
		pointer: "cursor",
		paddingTop: 15,
		opacity: 0,
		transform: "translateY(-15%)",
		transition: theme.transitions.create(["transform", "opacity", "visibility"]),
		visibility: "hidden",
		"&.isActive": {
			transform: "translateY(0)",
			opacity: 1,
			visibility: "visible",
		},
	};
});

const BoxSubUnivers = styled(Box)(({ theme }) => {
	return {
		display: "flex",
		backgroundColor: "rgba(255,255,255,0.5)",
		borderRadius: 15,
		pointer: "cursor",
	};
});

const UniversImage = styled("img")(({ theme }) => {
	return {
		width: "100%",
		objectFit: "contain",
	};
});

const UniversLink = styled("a")(({ theme }) => {
	return {
		width: 150,
		height: 100,
		display: "flex",
		borderRadius: 6,
		marginTop: 12,
		marginBottom: 12,
		cursor: "pointer",
	};
});

const Universe = () => {
	const { data: univers } = useGetUniversQuery();
	const [isOpen, setIsOpen] = React.useState(false);

	return (
		<Fade in={!!univers}>
			<Box
				onMouseEnter={() => setIsOpen(true)}
				onMouseLeave={() => setIsOpen(false)}
				sx={{ position: "relative" }}
			>
				<BoxUniversContainer className={isOpen ? "isActive" : ""}>
					<LogoContainer>
						<EarthStyle src={earth} alt={"earth-logo"} />
					</LogoContainer>
					<Typography fontFamily={"Branding Medium"} fontWeight={"600"}>
						Nos univers
					</Typography>
					<LogoContainer>
						<ChevronStyle className={"univers-chevron"} src={chevron_down} alt={"chevron-logo"} />
					</LogoContainer>
				</BoxUniversContainer>
				<UniversesList isOpen={isOpen} inSubMenu={true} />
			</Box>
		</Fade>
	);
};

export default Universe;
