import React, { useCallback, useEffect, useMemo } from "react";
import { useGetBillingFrequencyQuery } from "../../../../store/api/subscriptions";
import { SUBSCRIPTIONS_TYPES } from "../../../../routes/Abonnement";
import { Box, Fade, Skeleton, Typography } from "@mui/material";
import InexSelect from "../../../InexSelect";

const AbonnementFrequency = ({ subscriptionType, subscription, setSelectedPaiementFrequency }) => {
	const [currentFrequency, setCurrentFrequency] = React.useState(null);

	const { data: billingFrequency } = useGetBillingFrequencyQuery(
		{
			zone: subscription.zone,
			mag: +(subscriptionType === SUBSCRIPTIONS_TYPES.Mag),
			digital: +(subscriptionType === SUBSCRIPTIONS_TYPES.Digital),
			tv: +(subscriptionType === SUBSCRIPTIONS_TYPES.Tv),
		},
		{ skip: !subscription }
	);

	const _options = useMemo(() => {
		return billingFrequency?.map((item) => {
			return {
				name: `Abonnement ${item.titre.toLowerCase()} - ${parseInt(item.montant) / 100} €`,
				frequence: item.frequence,
			};
		});
	}, [billingFrequency]);

	useEffect(() => {
		if (_options) {
			const item = _options.find((item) => item.frequence === subscription.frequence);
			setSelectedPaiementFrequency(item);
			setCurrentFrequency(item);
		}
	}, [_options]);

	const _onFrequencyChange = useCallback(
		({ target }) => {
			setCurrentFrequency(target.value);
			setSelectedPaiementFrequency(target.value);
		},
		[_options]
	);

	if (!currentFrequency)
		return (
			<Skeleton
				sx={{
					backgroundColor: "white",
					boxShadow: (theme) => theme.shadows[25],
					borderRadius: "8px",
				}}
				variant="rectangular"
				width={"100%"}
				height={"73px"}
				animation={"pulse"}
			/>
		);
	return (
		<InexSelect
			value={currentFrequency}
			onChange={_onFrequencyChange}
			options={_options}
			label={"Fréquence d ’abonnement"}
			renderOptions={(option) => (
				<Box sx={{ paddingY: 1 }}>
					<Typography>{option.name}</Typography>
				</Box>
			)}
		/>
	);
};

export default AbonnementFrequency;
