import React from "react";
import { ContentContainer, Title } from "./Abonnement";
import InexTextField from "../components/InexTextField";
import InexButton from "../components/InexButton";
import {
	Box,
	Collapse,
	FormControlLabel,
	Grid,
	IconButton,
	InputAdornment,
	Radio,
	RadioGroup,
	Skeleton,
} from "@mui/material";

import AddressesList from "../components/profil/AddressesList";
import { useDispatch, useSelector } from "react-redux";
import { getUser, setUser } from "../store/reducers/authSlice";
import { useIsTablet } from "../hooks/responsive";

import phoneIcon from "../resources/images/svg/mon-profil/phone@1,5x.svg";
import smartphoneIcon from "../resources/images/svg/mon-profil/smartphone@1,5x.svg";
import resetIcon from "../resources/images/svg/resetIcon.svg";
import { useUpdateUserMutation } from "../store/api/user";
import { setShowSnackbar, setSnackbarSeverity, setSnackbarText } from "../store/reducers/snackbarSlice";
import { useRedirectToLogin } from "../hooks/auth";
import PasswordChangeModal from "../components/profil/PasswordChangeModal";
import withAuth from "../HOC/withAuth";
import useTabTitle from "../hooks/useTabTitle";

const Profil = () => {
	useTabTitle({ customTitle: "Mon profil" });
	useRedirectToLogin();

	const isTablet = useIsTablet();
	const user = useSelector(getUser);

	const reduceFunc = (state, action) => {
		return { ...state, [action.field]: action.value };
	};
	const [formValues, setFormValue] = React.useReducer(reduceFunc, {
		civility: user?.civilite,
		firstName: user?.prenom,
		lastName: user?.nom,
		telephone: user?.telephone,
		phone: user?.portable,
	});

	const restFormValues = () => {
		if (user) {
			setFormValue({ field: "civility", value: user.civilite });
			setFormValue({ field: "firstName", value: user.prenom });
			setFormValue({ field: "lastName", value: user.nom });
			setFormValue({ field: "telephone", value: user.telephone });
			setFormValue({ field: "phone", value: user.portable });
		}
	};
	React.useEffect(() => {
		restFormValues();
	}, [user]);

	const checkPhoneNumber = (val) => {
		const reg =
			/^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/;
		return reg.test(val);
	};

	const dispatch = useDispatch();
	const [updateUser] = useUpdateUserMutation();
	const [isFetching, setIsFetching] = React.useState(false);

	const [passResetModalOpen, setPassResetModalOpen] = React.useState(false);

	const [errors, setError] = React.useState({});

	const setShowSnackBar = () => {
		dispatch(setShowSnackbar(true));
		dispatch(setSnackbarSeverity("success"));
		dispatch(setSnackbarText("Vos informations ont bien été mises à jour."));
	};

	const saveUserInfoChanges = () => {
		let hasError = false;
		const tmp = JSON.parse(JSON.stringify(errors));
		if (
			user.telephone !== formValues.telephone &&
			!checkPhoneNumber(formValues.telephone) &&
			formValues.telephone !== ""
		) {
			hasError = true;
			tmp.telephone = "Numéro de téléphone incorrect ou incomplet.";
		} else {
			tmp.telephone = undefined;
		}
		if (user.portable !== formValues.phone && !checkPhoneNumber(formValues.phone) && formValues.phone !== "") {
			hasError = true;
			tmp.phone = "Numéro de téléphone incorrect ou incomplet.";
		} else {
			tmp.phone = undefined;
		}
		setError(tmp);

		if (!hasError) {
			setIsFetching(true);
			updateUser({
				civilite: formValues.civility,
				nom: formValues.lastName,
				prenom: formValues.firstName,
				telephone: formValues.telephone,
				portable: formValues.phone,
			}).then(({ data }) => {
				setIsFetching(false);
				if (data.client) {
					dispatch(setUser(data.client));
					setShowSnackBar();
				} else restFormValues();
			});
		}
	};

	// const [passResetModalOpen, setPassResetModalOpen] = React.useState(false);

	return (
		<ContentContainer container item className={"content-background"}>
			<Grid
				item
				xs={12}
				sx={{
					marginBottom: (theme) => theme.spacing(2),
				}}
			>
				<Title>Mon identifiant</Title>
			</Grid>
			<Grid
				container
				item
				xs={12}
				display={"flex"}
				alignItems={"center"}
				justifyContent={"flex-start"}
				spacing={2}
			>
				<Grid item xs={12} md={12} lg={10} xl={10} flexWrap={"nowrap"}>
					{!user ? (
						<Skeleton
							width={"100%"}
							height={"58px"}
							sx={{
								backgroundColor: "white",
								boxShadow: (theme) => theme.shadows[25],
								borderRadius: "8px",
							}}
							variant="rectangular"
							animation={"pulse"}
						/>
					) : (
						<InexTextField
							disabled={true}
							label={"Mon adresse email"}
							value={user?.email}
							formControlSx={{
								width: "100%",
							}}
						/>
					)}
				</Grid>
				{/*<Grid item xs={12} md={4} lg={3} xl={2} flexWrap={"nowrap"}>*/}
				{/*	{!user ? (*/}
				{/*		<Skeleton*/}
				{/*			width={"100%"}*/}
				{/*			height={"58px"}*/}
				{/*			sx={{*/}
				{/*				backgroundColor: "white",*/}
				{/*				boxShadow: (theme) => theme.shadows[25],*/}
				{/*				borderRadius: "28px",*/}
				{/*			}}*/}
				{/*			variant="rectangular"*/}
				{/*			animation={"pulse"}*/}
				{/*		/>*/}
				{/*	) : (*/}
				{/*		<InexButton*/}
				{/*			text={"Modifier mon email"}*/}
				{/*			sx={{*/}
				{/*				width: "100%",*/}
				{/*				height: "50px",*/}
				{/*			}}*/}
				{/*		/>*/}
				{/*	)}*/}
				{/*</Grid>*/}
			</Grid>

			<Grid
				item
				xs={12}
				sx={{
					marginTop: (theme) => theme.spacing(5),
				}}
			>
				<Title>Mon mot de passe</Title>
			</Grid>
			<Grid
				container
				item
				xs={12}
				display={"flex"}
				alignItems={"center"}
				justifyContent={"flex-start"}
				spacing={2}
				pt={2}
			>
				<Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
					{!user ? (
						<Skeleton
							width={"100%"}
							height={"50px"}
							sx={{
								backgroundColor: "white",
								boxShadow: (theme) => theme.shadows[25],
								borderRadius: "28px",
							}}
							variant="rectangular"
							animation={"pulse"}
						/>
					) : (
						<InexButton
							text={"Modifier mon mot de passe"}
							onClick={() => {
								setPassResetModalOpen(true);
							}}
							sx={{
								width: "100%",
								height: "50px",
							}}
						/>
					)}
				</Grid>
				<PasswordChangeModal
					open={passResetModalOpen}
					onClose={() => {
						setPassResetModalOpen(false);
					}}
				/>
			</Grid>

			<Grid
				item
				xs={12}
				sx={{
					marginTop: (theme) => theme.spacing(5),
				}}
			>
				<Title>Mes informations personnelles</Title>
			</Grid>
			<Grid
				container
				item
				xs={12}
				display={"flex"}
				alignItems={"center"}
				justifyContent={"flex-start"}
				spacing={2}
				pt={2}
			>
				<Grid item xs={12}>
					{!user ? (
						<Skeleton
							width={isTablet ? "100%" : "500px"}
							height={"42px"}
							sx={{
								backgroundColor: "white",
								boxShadow: (theme) => theme.shadows[25],
								borderRadius: "21px",
							}}
							variant="rectangular"
							animation={"pulse"}
						/>
					) : (
						<RadioGroup
							defaultValue={user.civilite}
							value={formValues.civility}
							onChange={(e) => {
								setFormValue({ field: "civility", value: e.target.value });
							}}
							sx={{
								display: "flex",
								flexDirection: "row",
							}}
						>
							<FormControlLabel
								value="Madame"
								control={<Radio color={"secondary"} />}
								label="Madame"
								sx={{
									marginRight: (theme) => theme.spacing(5),
								}}
							/>
							<FormControlLabel
								value="Monsieur"
								control={<Radio color={"secondary"} />}
								label="Monsieur"
								sx={{
									marginRight: (theme) => theme.spacing(5),
								}}
							/>
							<FormControlLabel
								value="Je ne souhaite pas préciser"
								control={<Radio color={"secondary"} />}
								label="Je ne souhaite pas préciser"
								sx={{
									marginRight: (theme) => theme.spacing(5),
								}}
							/>
						</RadioGroup>
					)}
				</Grid>
				<Grid item xs={12} md={6} lg={5}>
					{!user ? (
						<Skeleton
							width={"100%"}
							height={"58px"}
							sx={{
								backgroundColor: "white",
								boxShadow: (theme) => theme.shadows[25],
								borderRadius: "8px",
							}}
							variant="rectangular"
							animation={"pulse"}
						/>
					) : (
						<InexTextField
							label={"Mon prénom"}
							defaultValue={user.prenom}
							value={formValues.firstName}
							onChange={(e) => {
								setFormValue({ field: "firstName", value: e.target.value });
							}}
							formControlSx={{
								width: "100%",
							}}
							endAdornment={
								<InputAdornment position={"end"}>
									{user.prenom !== formValues.firstName && (
										<IconButton
											onClick={() => {
												setFormValue({ field: "firstName", value: user.prenom });
											}}
											sx={{
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
												flexDirection: "column",
											}}
										>
											<img src={resetIcon} alt={"resetIcon"} />
										</IconButton>
									)}
								</InputAdornment>
							}
						/>
					)}
				</Grid>
				<Grid item xs={12} md={6} lg={5}>
					{!user ? (
						<Skeleton
							width={"100%"}
							height={"58px"}
							sx={{
								backgroundColor: "white",
								boxShadow: (theme) => theme.shadows[25],
								borderRadius: "8px",
							}}
							variant="rectangular"
							animation={"pulse"}
						/>
					) : (
						<InexTextField
							label={"Mon nom"}
							defaultValue={user.nom}
							value={formValues.lastName}
							onChange={(e) => {
								setFormValue({ field: "lastName", value: e.target.value });
							}}
							formControlSx={{
								width: "100%",
							}}
							endAdornment={
								<InputAdornment position={"end"}>
									{user.nom !== formValues.lastName && (
										<IconButton
											onClick={() => {
												setFormValue({ field: "lastName", value: user.nom });
											}}
											sx={{
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
												flexDirection: "column",
											}}
										>
											<img src={resetIcon} alt={"resetIcon"} />
										</IconButton>
									)}
								</InputAdornment>
							}
						/>
					)}
				</Grid>
				<Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
					<Collapse
						in={
							user &&
							(user.civilite !== formValues.civility ||
								user.prenom !== formValues.firstName ||
								user.nom !== formValues.lastName)
						}
					>
						<InexButton
							text={"Sauvegarder mes modifications"}
							onClick={saveUserInfoChanges}
							loading={isFetching}
							sx={{
								width: "100%",
								height: "50px",
							}}
						/>
					</Collapse>
				</Grid>

				<Grid
					item
					xs={12}
					sx={{
						marginTop: (theme) => theme.spacing(2),
					}}
				>
					<Title>Pour me contacter</Title>
				</Grid>
				<Grid container item xs={12} spacing={2}>
					<Grid item xs={12} md={6} lg={5}>
						{!user ? (
							<Skeleton
								width={"100%"}
								height={"58px"}
								sx={{
									backgroundColor: "white",
									boxShadow: (theme) => theme.shadows[25],
									borderRadius: "8px",
								}}
								variant="rectangular"
								animation={"pulse"}
							/>
						) : (
							<InexTextField
								label={"Mon téléphone fixe"}
								defaultValue={user.telephone}
								value={formValues.telephone ? formValues.telephone : ""}
								error={errors.telephone !== undefined}
								helperText={errors.telephone}
								onChange={(e) => {
									setFormValue({ field: "telephone", value: e.target.value });
								}}
								formControlSx={{
									width: "100%",
								}}
								endAdornment={
									<InputAdornment position={"end"}>
										{user.telephone !== formValues.telephone && (
											<IconButton
												onClick={() => {
													setFormValue({ field: "telephone", value: user.telephone });
													setError({ ...errors, telephone: undefined });
												}}
												sx={{
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
													flexDirection: "column",
												}}
											>
												<img src={resetIcon} alt={"resetIcon"} />
											</IconButton>
										)}
										<Box
											ml={2}
											sx={{
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
												flexDirection: "column",
											}}
										>
											<img src={phoneIcon} alt={"phoneIcon"} />
										</Box>
									</InputAdornment>
								}
							/>
						)}
					</Grid>
					<Grid item xs={12} md={6} lg={5}>
						{!user ? (
							<Skeleton
								width={"100%"}
								height={"58px"}
								sx={{
									backgroundColor: "white",
									boxShadow: (theme) => theme.shadows[25],
									borderRadius: "8px",
								}}
								variant="rectangular"
								animation={"pulse"}
							/>
						) : (
							<InexTextField
								label={"Mon portable"}
								defaultValue={user.portable}
								value={formValues.phone ? formValues.phone : ""}
								onChange={(e) => {
									setFormValue({ field: "phone", value: e.target.value });
								}}
								error={errors.phone !== undefined}
								helperText={errors.phone}
								formControlSx={{
									width: "100%",
								}}
								endAdornment={
									<InputAdornment position={"end"}>
										{user.portable !== formValues.phone && (
											<IconButton
												onClick={() => {
													setFormValue({ field: "phone", value: user.portable });
													setError({ ...errors, phone: undefined });
												}}
												sx={{
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
													flexDirection: "column",
												}}
											>
												<img src={resetIcon} alt={"resetIcon"} />
											</IconButton>
										)}
										<Box
											ml={2}
											sx={{
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
												flexDirection: "column",
											}}
										>
											<img src={smartphoneIcon} alt={"smartphoneIcon"} />
										</Box>
									</InputAdornment>
								}
							/>
						)}
					</Grid>
				</Grid>
				<Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
					<Collapse
						in={user && (user.portable !== formValues.phone || user.telephone !== formValues.telephone)}
					>
						<InexButton
							text={"Sauvegarder mes modifications"}
							onClick={saveUserInfoChanges}
							loading={isFetching}
							sx={{
								width: "100%",
								height: "50px",
							}}
						/>
					</Collapse>
				</Grid>

				<Grid
					item
					xs={12}
					sx={{
						marginTop: (theme) => theme.spacing(2),
					}}
				>
					<Title>MON CARNET D'ADRESSES</Title>
				</Grid>
				<Grid item xs={12}>
					<AddressesList />
				</Grid>
			</Grid>
		</ContentContainer>
	);
};

export default withAuth(Profil);
