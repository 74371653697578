import React from "react";
import { ContentContainer, Title } from "./Abonnement";
import { Alert, Collapse, Grid, Skeleton, Snackbar, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getUser, setUser } from "../store/reducers/authSlice";
import InexSwitch from "../components/InexSwitch";
import InexButton from "../components/InexButton";
import { useUpdateUserNewslettersMutation } from "../store/api/user";
import { setShowSnackbar, setSnackbarSeverity, setSnackbarText } from "../store/reducers/snackbarSlice";
import withAuth from "../HOC/withAuth";
import useTabTitle from "../hooks/useTabTitle";

const Newsletter = () => {
	const user = useSelector(getUser);
	useTabTitle({ customTitle: "Mes newsletters" });

	const reduceFunc = (state, action) => {
		return { ...state, [action.field]: action.value };
	};
	const [formValues, setFormValue] = React.useReducer(reduceFunc, {
		actu: !!user?.newsletters.actu,
		film: !!user?.newsletters.film,
		notif: !!user?.newsletters.notif,
		promo: !!user?.newsletters.promo,
	});

	React.useEffect(() => {
		setFormValue({ field: "actu", value: !!user?.newsletters.actu });
		setFormValue({ field: "film", value: !!user?.newsletters.film });
		setFormValue({ field: "notif", value: !!user?.newsletters.notif });
		setFormValue({ field: "promo", value: !!user?.newsletters.promo });
	}, [user]);

	const [updateNewsletters] = useUpdateUserNewslettersMutation();
	const [isFetching, setIsFetching] = React.useState(false);

	const dispatch = useDispatch();
	const setShowSnackBar = () => {
		dispatch(setShowSnackbar(true));
		dispatch(setSnackbarSeverity("success"));
		dispatch(setSnackbarText("Vos informations ont bien été mises à jour."));
	};

	const saveUserInfoChanges = () => {
		setIsFetching(true);
		updateNewsletters({
			hasNotif: formValues.notif,
			hasPromo: formValues.promo,
			hasActu: formValues.actu,
			hasSeb: formValues.film,
		}).then(({ data }) => {
			console.log(data);
			setIsFetching(false);
			if (data) {
				dispatch(setUser(data.user));
				setShowSnackBar();
			}
		});
	};

	return (
		<ContentContainer container item className={"content-background"}>
			<Grid
				item
				xs={12}
				sx={{
					marginBottom: (theme) => theme.spacing(2),
				}}
			>
				<Title>Liste des Newsletters disponibles</Title>
			</Grid>
			<Grid container xs={12} spacing={3} mt={0}>
				<Grid item xs={12} display={"flex"} flexWrap={"nowrap"} alignItems={"center"}>
					{!user ? (
						<Skeleton
							width={"100%"}
							height={"30px"}
							sx={{
								backgroundColor: "white",
								boxShadow: (theme) => theme.shadows[25],
								borderRadius: "8px",
							}}
							variant="rectangular"
							animation={"pulse"}
						/>
					) : (
						<>
							<InexSwitch
								checked={formValues.promo}
								onClick={() => {
									setFormValue({ field: "promo", value: !formValues.promo });
								}}
							/>
							<Typography
								color={"secondary"}
								fontSize={20}
								sx={{
									fontFamily: "Branding SemiBold",
									marginLeft: (theme) => theme.spacing(3),
								}}
							>
								Des offres et nouveautés commerciales des univers Inexploré.
							</Typography>
						</>
					)}
				</Grid>
				<Grid item xs={12} display={"flex"} flexWrap={"nowrap"} alignItems={"center"}>
					{!user ? (
						<Skeleton
							width={"100%"}
							height={"30px"}
							sx={{
								backgroundColor: "white",
								boxShadow: (theme) => theme.shadows[25],
								borderRadius: "8px",
							}}
							variant="rectangular"
							animation={"pulse"}
						/>
					) : (
						<>
							<InexSwitch
								checked={formValues.actu}
								onClick={() => {
									setFormValue({ field: "actu", value: !formValues.actu });
								}}
							/>
							<Typography
								color={"secondary"}
								fontSize={20}
								sx={{
									fontFamily: "Branding SemiBold",
									marginLeft: (theme) => theme.spacing(3),
								}}
							>
								Des contenus 100% gratuits (articles, podcasts, vidéos...).
							</Typography>
						</>
					)}
				</Grid>
				<Grid item xs={12} display={"flex"} flexWrap={"nowrap"} alignItems={"center"}>
					{!user ? (
						<Skeleton
							width={"100%"}
							height={"30px"}
							sx={{
								backgroundColor: "white",
								boxShadow: (theme) => theme.shadows[25],
								borderRadius: "8px",
							}}
							variant="rectangular"
							animation={"pulse"}
						/>
					) : (
						<>
							<InexSwitch
								checked={formValues.film}
								onClick={() => {
									setFormValue({ field: "film", value: !formValues.film });
								}}
							/>
							<Typography
								color={"secondary"}
								fontSize={20}
								sx={{
									fontFamily: "Branding SemiBold",
									marginLeft: (theme) => theme.spacing(3),
								}}
							>
								Des actualités de Sébastien Lilli (rédacteur en chef et créateur d'Inexploré).
							</Typography>
						</>
					)}
				</Grid>
				<Grid item xs={12} display={"flex"} flexWrap={"nowrap"} alignItems={"center"}>
					{!user ? (
						<Skeleton
							width={"100%"}
							height={"30px"}
							sx={{
								backgroundColor: "white",
								boxShadow: (theme) => theme.shadows[25],
								borderRadius: "8px",
							}}
							variant="rectangular"
							animation={"pulse"}
						/>
					) : (
						<>
							<InexSwitch
								checked={formValues.notif}
								onClick={() => {
									setFormValue({ field: "notif", value: !formValues.notif });
								}}
							/>
							<Typography
								color={"secondary"}
								fontSize={20}
								sx={{
									fontFamily: "Branding SemiBold",
									marginLeft: (theme) => theme.spacing(3),
								}}
							>
								Les notifications relatives à vos abonnements Inexploré magazine, digital et TV (sorties
								de magazines et de nouvelles vidéos, fin d'abonnement...).
							</Typography>
						</>
					)}
				</Grid>
				<Grid item xs={12} display={"flex"} flexWrap={"nowrap"} alignItems={"center"} mt={2}>
					{!user ? (
						<Skeleton
							width={"100%"}
							height={"60px"}
							sx={{
								backgroundColor: "white",
								boxShadow: (theme) => theme.shadows[25],
								borderRadius: "8px",
							}}
							variant="rectangular"
							animation={"pulse"}
						/>
					) : (
						<Typography
							color={"secondary"}
							fontSize={20}
							sx={{
								fontFamily: "Branding SemiBold",
							}}
						>
							Pour vous tenir informés des points d'évolutions importants (CGV, tarifs, conditions
							d'abonnement) nous vous enverrons une newsletter. Cette dernière étant une obligation
							légale, il n'est pas possible de s'en désabonner.
						</Typography>
					)}
				</Grid>
			</Grid>
			<Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
				<Collapse
					in={
						user &&
						(!!user.newsletters.actu !== formValues.actu ||
							!!user.newsletters.promo !== formValues.promo ||
							!!user.newsletters.film !== formValues.film ||
							!!user.newsletters.notif !== formValues.notif)
					}
				>
					<InexButton
						text={"Sauvegarder mes modifications"}
						onClick={saveUserInfoChanges}
						loading={isFetching}
						sx={{
							width: "100%",
							height: "50px",
							marginTop: (theme) => theme.spacing(4),
						}}
					/>
				</Collapse>
			</Grid>
		</ContentContainer>
	);
};

export default withAuth(Newsletter);
