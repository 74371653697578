import React, { useMemo } from "react";
import { ContentContainer, Title } from "./Abonnement";
import { Grid } from "@mui/material";
import InexBreadcrumbs from "../components/InexBreadcrumbs";
import { useGetFaqCategoriesQuery, useLazyGetCategoryQuestionsQuery, useLazyGetFaqAnswerQuery } from "../store/api/faq";
import FaqCategories from "../components/faq/Categories";
import FaqQuestions from "../components/faq/Questions";
import FaqAnswer from "../components/faq/Answer";
import { useSearchParams } from "react-router-dom";
import { useAuthToken, useLogUserIn } from "../hooks/auth";
import { useGetUserQuery } from "../store/api/user";
import useTabTitle from "../hooks/useTabTitle";

const BREADCRUMBS_TITLES = ["Liste des rubriques", "Questions", "Réponse"];

const FAQ = () => {
	useTabTitle({ customTitle: "Foire aux questions" });

	const { data: categories } = useGetFaqCategoriesQuery();
	let [searchParams, setSearchParams] = useSearchParams();
	const [questions, setQuestions] = React.useState();
	const [answer, setAnswer] = React.useState();
	const [category, setCategory] = React.useState("");

	useLogUserIn();
	const token = useAuthToken();
	useGetUserQuery({}, { skip: !token });

	const [getQuestions] = useLazyGetCategoryQuestionsQuery();
	const [getAnswer] = useLazyGetFaqAnswerQuery();

	const breadCrumbs = useMemo(() => {
		if (!questions && !answer) {
			return [{ title: BREADCRUMBS_TITLES[0] }];
		} else if (!!questions && !answer) {
			return [{ title: BREADCRUMBS_TITLES[0], href: {} }, { title: category }];
		}
		return [
			{ title: BREADCRUMBS_TITLES[0], href: {} },
			{ title: category, href: { categorie: searchParams.get("categorie") } },
			{
				title: BREADCRUMBS_TITLES[2],
			},
		];
	}, [questions, category, answer]);

	React.useEffect(() => {
		const categoryParam = searchParams.get("categorie");
		const questionParam = searchParams.get("question");
		if (!!categoryParam) {
			getQuestions({ id: categoryParam }).then((data) => {
				if (data?.data) {
					setQuestions(data.data.questions);
					setCategory(data.data.categorie?.titre);
				}
			});
		} else {
			setQuestions(null);
		}
		if (!!questionParam) {
			getAnswer({ id: questionParam }).then((data) => {
				if (data?.data) {
					setAnswer(data.data);
				}
			});
		} else {
			setAnswer(null);
		}
	}, [searchParams]);

	return (
		<ContentContainer container item className={"content-background"}>
			<Grid
				container
				item
				xs={12}
				sx={{
					marginBottom: (theme) => theme.spacing(2),
				}}
				spacing={3}
			>
				<Grid item xs={12}>
					<Title>Foire aux questions</Title>
				</Grid>
				<Grid item xs={12}>
					<InexBreadcrumbs breadcrumbs={breadCrumbs} />
				</Grid>
			</Grid>
			{!questions && !answer && <FaqCategories categories={categories} />}
			{!answer && <FaqQuestions questions={questions} />}
			{!!answer && <FaqAnswer answer={answer} />}
		</ContentContainer>
	);
};

export default FAQ;
