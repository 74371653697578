import { Breadcrumbs, Link, styled } from "@mui/material";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

const LinkStyle = styled(Link)(({ theme }) => {
	return {
		fontFamily: "Branding SemiBold",
		color: theme.palette.secondary.main,
		fontSize: 16,
	};
});

const InexBreadcrumbs = ({ breadcrumbs }) => {
	let [searchParams, setSearchParams] = useSearchParams();

	const onHref = (href) => {
		if (!href) return;
		console.log(href);
		setSearchParams(href);
	};

	const generatedBreadCrumbs = useMemo(() => {
		return breadcrumbs.map((bc, idx) => (
			<LinkStyle
				key={bc.title}
				underline={idx < breadcrumbs.length - 1 ? "always" : "none"}
				sx={{ cursor: idx < breadcrumbs.length - 1 ? "pointer" : "auto" }}
				// href={idx < breadcrumbs.length - 1 ? bc.href : null}
				onClick={() => onHref(bc.href)}
			>
				{bc.title}
			</LinkStyle>
		));
	}, [breadcrumbs]);

	return <Breadcrumbs separator="›">{generatedBreadCrumbs}</Breadcrumbs>;
};

export default InexBreadcrumbs;
