import React from "react";
import { Box, Container, Grid, IconButton, Typography } from "@mui/material";
import logoMobile from "../../resources/images/svg/logo_mobile.svg";
import burgerMenu from "../../resources/images/svg/Close.svg";
import { SideBar } from "../../routes/RoutesDefinitions";
import UniversesList from "./UniversesList";

const MobileMenu = ({ isOpen, close }) => {
	return (
		<Box
			sx={{
				position: "fixed",
				width: "100%",
				height: isOpen ? "100%" : 0,
				zIndex: 10000,
				left: 0,
				top: 0,
				overflowX: "hidden",
				transition: "all 0.5s",
				backgroundColor: "white",
				background:
					"linear-gradient(160deg, rgba(237,169,171,1) 6%, rgba(255,223,223,1) 26%, rgba(238,197,173,1) 51%, rgba(255,230,215,1) 69%, rgba(252,240,202,1) 87%);",
			}}
		>
			<Container
				sx={{
					position: "relative",
					width: "100%",
					paddingY: 5,
					height: "100%",
					paddingX: 1,
					background: "rgba(255,255,255,0.6)",
				}}
				px={3}
				py={3}
			>
				<Grid container direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
					<Grid item justifyContent={"flex-start"} alignItems={"center"} display={"flex"} px={1}>
						<img src={logoMobile} className="" alt="logo" style={{}} />
					</Grid>
					<IconButton sx={{ bottom: "6px" }} onClick={close}>
						<img style={{ width: "30px" }} src={burgerMenu} alt={"burger-menu"} />
					</IconButton>
				</Grid>
				<SideBar onClick={close} />
				<Typography
					ml={3}
					mt={1}
					color={"secondary"}
					sx={{
						fontFamily: "Branding SemiBold",
						fontSize: "18px",
					}}
				>
					{"Nos univers"}
				</Typography>
				<UniversesList />
			</Container>
		</Box>
	);
};

export default MobileMenu;
