import React, { useCallback, useEffect, useMemo } from "react";
import { Box, Grid, IconButton, Popover, Radio, styled, Typography } from "@mui/material";
import InexCard from "../InexCard";
import {
	Dot,
	FieldText,
	PaiementMethodContent,
	PaiementMethodTitle,
	TopBorder,
} from "../abonnement/home/modalEdition/AbonnementPaiementList";
import ErrorOutlineIcon from "@mui/icons-material/Error";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import moment from "moment/moment";
import { useSelector } from "react-redux";
import { getUser } from "../../store/reducers/authSlice";

const DonationPaymentMethod = ({ infos, currentDonation, index, selectedIndex, setSelectedIndex }) => {
	const user = useSelector(getUser);
	const _isSepa = useMemo(() => {
		return !infos.datefin;
	}, [infos]);

	const isPaiementMethodSelected = useMemo(() => {
		return index === selectedIndex;
	}, [index, selectedIndex]);

	const _expDate = useMemo(() => {
		if (!infos.datefin) return null;
		let [month, year] = [infos.datefin.slice(0, 2), infos.datefin.slice(2)];
		year = `20${year}`;
		return {
			month,
			year,
		};
	}, [infos]);

	React.useEffect(() => {
		if (currentDonation?.typerenouv === 3 && !infos.datefin) {
			setSelectedIndex(index);
		}
		if (currentDonation?.typerenouv === 2 && infos?.idcb === parseInt(currentDonation.cb)) {
			setSelectedIndex(index);
		}
	}, []);

	const _onRadioPressed = useCallback(() => {
		setSelectedIndex(index);
	}, [index]);

	const is_card_valid = useMemo(() => {
		if (!_expDate) return true;
		const currentYear = moment().year();
		const currentMonth = moment().month();
		if (parseInt(_expDate.year, 10) < currentYear) return false;
		if (parseInt(_expDate.year, 10) === currentYear && parseInt(_expDate.month, 10) < currentMonth) return false;
		return true;
	}, [_expDate]);

	return (
		<Grid item xs={12}>
			<InexCard
				onClick={_onRadioPressed}
				selected={isPaiementMethodSelected}
				sx={{
					padding: (theme) => theme.spacing(2),
					paddingTop: (theme) => theme.spacing(2),
					paddingBottom: (theme) => theme.spacing(2),
					paddingLeft: 0,
					backgroundColor: !is_card_valid && "#FAE7EC",
					"&:hover": {
						cursor: "pointer",
					},
				}}
			>
				<TopBorder selected={isPaiementMethodSelected} />
				{!is_card_valid && (
					<Box sx={{ display: "flex", marginLeft: (theme) => theme.spacing(5) }}>
						<ErrorOutlineIcon sx={{ fontSize: 25, color: "#F07892" }} />
						<Typography sx={{ color: "#F07892", marginLeft: (theme) => theme.spacing(1) }}>
							Attention ce mode de paiement n’est plus valide.
						</Typography>
					</Box>
				)}
				<Grid sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
					<Box display={"flex"} alignItems={"center"} sx={{ marginLeft: (theme) => theme.spacing(5) }}>
						<PaiementMethodTitle>{_isSepa ? "Prélèvement SEPA" : "Carte bancaire"}</PaiementMethodTitle>
					</Box>
					<Radio
						checked={isPaiementMethodSelected}
						onChange={_onRadioPressed}
						value="a"
						name="radio-buttons"
						inputProps={{ "aria-label": "A" }}
						sx={{
							color: (theme) => theme.palette.secondary.main,
							"&.Mui-checked": {
								color: (theme) => theme.palette.secondary.main,
							},
						}}
					/>
				</Grid>
				<Grid
					container
					sx={{
						display: "flex",
						alignItems: "center",
						marginLeft: (theme) => theme.spacing(5),
						marginTop: (theme) => theme.spacing(1),
					}}
				>
					{!_isSepa ? (
						<>
							<Grid item xs={4}>
								<FieldText>Numéro</FieldText>
								<PaiementMethodContent component={"span"}>
									<Typography component={"span"} sx={{ display: "flex", alignItems: "center" }}>
										<Dot nb={4} />
										&nbsp;-&nbsp;
										<Dot nb={2} />
										{infos.cb}
									</Typography>
								</PaiementMethodContent>
							</Grid>
							<Grid item xs={4}>
								<FieldText>Date d'expiration</FieldText>
								<PaiementMethodContent component={"span"}>
									<Typography
										sx={{ display: "flex", alignItems: "center" }}
										color={!is_card_valid && "#F07892"}
									>
										{`${_expDate?.month}/${_expDate?.year}`}
									</Typography>
								</PaiementMethodContent>
							</Grid>
						</>
					) : (
						<Grid item xs={4}>
							<FieldText>Titulaire</FieldText>
							<PaiementMethodContent component={"span"}>
								<Typography sx={{ display: "flex", alignItems: "center" }}>
									{`${user.prenom.capitalize()} ${user.nom.toUpperCase()}`}
								</Typography>
							</PaiementMethodContent>
						</Grid>
					)}
				</Grid>
			</InexCard>
		</Grid>
	);
};

export default DonationPaymentMethod;
