import { api } from "./rtkApi";
import { store } from "../store";
import { encode } from "querystring";

export const ordersApi = api.injectEndpoints({
	endpoints: (build) => ({
		getOrder: build.query({
			query: ({ idcommande }) => {
				const state = store.getState();
				let authToken = state.auth.authToken;
				return {
					method: "POST",
					url: `/abo/get-commande`,
					headers: {
						authorization: "Bearer " + authToken,
						"Content-Type": "application/x-www-form-urlencoded",
					},
					body: encode({
						idcommande,
					}),
				};
			},
		}),
		getAllOrders: build.query({
			query: () => {
				const state = store.getState();
				let authToken = state.auth.authToken;
				const now = new Date();
				return {
					method: "POST",
					url: `/abo/all-commandes?ts=${now.toISOString()}`,
					headers: {
						authorization: "Bearer " + authToken,
					},
				};
			},
		}),
	}),
	overrideExisting: true,
});

export const { useGetAllOrdersQuery, useLazyGetAllOrdersQuery, useGetOrderQuery, useLazyGetOrderQuery } = ordersApi;
