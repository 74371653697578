import React from "react";
import { BrowserRouter, NavLink, Route, Routes, useNavigate } from "react-router-dom";
import { Alert, Box, Grid, List, Snackbar, styled } from "@mui/material";
import { ReactComponent as CheckCircle } from "../resources/images/svg/sidebar/check_circle.svg";
import { ReactComponent as CustomerCard } from "../resources/images/svg/sidebar/customer_card.svg";
import { ReactComponent as ForYou } from "../resources/images/svg/sidebar/For_you.svg";
import { ReactComponent as LifeBuoy } from "../resources/images/svg/sidebar/life_buoy.svg";
import { ReactComponent as LogOut } from "../resources/images/svg/sidebar/log_out.svg";
import { ReactComponent as MailOpen } from "../resources/images/svg/sidebar/mail_Open.svg";
import { ReactComponent as Order } from "../resources/images/svg/sidebar/order.svg";
import { ReactComponent as User } from "../resources/images/svg/sidebar/user.svg";
import { ReactComponent as Mail } from "../resources/images/svg/sidebar/mail.svg";

import { getLoginRedirectURL, useAuthToken, useIsLoggedIn, useLogUserIn, useRedirectToLogin } from "../hooks/auth";
import Abonnement from "./Abonnement";
import { useIsMobile } from "../hooks/responsive";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import Profil from "./Profil";
import Newsletter from "./Newsletter";
import FAQ from "./FAQ";
import Contact from "./Contact";

import { useDispatch, useSelector } from "react-redux";
import {
	setShowSnackbar,
	getShowSnackbar,
	getSnackbarText,
	getSnackbarSeverity,
} from "../store/reducers/snackbarSlice";
import Orders from "./Orders";
import SeeOrder from "./SeeOrder";
import Cookies from "universal-cookie";
import { setAuthToken, setInreesId, setInreesToken } from "../store/reducers/authSlice";
import Donations from "./Donations";
import DonationSuccess from "./DonationSuccess";
import DonationFailure from "./DonationFailure";

export const LinkStyle = styled("span")(({ theme, selected }) => {
	return {
		color: "#363B5A",
		maxWidth: 160,
		fontFamily: "Branding SemiBold",
	};
});

const ListItemStyle = styled(NavLink)(({ theme, selected }) => {
	const isMobile = useIsMobile();
	return {
		marginTop: theme.spacing(isMobile ? 0.3 : 1),
		marginBottom: theme.spacing(isMobile ? 0.3 : 1),
		cursor: "pointer",
		borderRadius: 28,
		marginLeft: 10,
		marginRight: 10,
		height: 50,
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-start",
		transition: "all 0.3s",
		textDecoration: "none",
		zIndex: 20,
		position: "inherit",
		svg: {
			marginLeft: 15,
			marginRight: 10,
			fill: "#363B5A",
		},
		"&:hover": {
			background:
				"linear-gradient(90deg, rgba(238, 121, 146, 0.2) 0%, rgba(241, 174, 101, 0.2) 50.51%, rgba(244, 197, 77, 0.2) 100%)",
		},
		"&.active": {
			background:
				"linear-gradient(90deg, rgba(238, 121, 146, 1) 0%, rgba(241, 174, 101, 1) 50.51%, rgba(244, 197, 77, 1) 100%)",
			span: {
				color: "white",
			},
			svg: {
				fill: "white !important",
			},
		},
	};
});

export const SideBar = ({ onClick = () => null }) => {
	const isMobile = useIsMobile();
	const dispatch = useDispatch();

	const isLoggedIn = useIsLoggedIn();

	const login = () => {
		const redirectUrl = getLoginRedirectURL();
		window.location.assign(redirectUrl);
	};

	const logOut = (e) => {
		e.preventDefault();
		e.stopPropagation();
		const cookies = new Cookies();
		cookies.remove("INEXPLORE_USER", {
			path: "/",
			domain: ".inexplore.com",
		});
		cookies.remove("INEXPLORE_TOKEN", {
			path: "/",
			domain: ".inexplore.com",
		});
		cookies.remove("INEXPLORE_ID", {
			path: "/",
			domain: ".inexplore.com",
		});
		dispatch(setInreesId(null));
		dispatch(setInreesToken(null));
		dispatch(setAuthToken(null));
		const redirectUrl = getLoginRedirectURL();
		window.location.href = redirectUrl;
	};

	return (
		<Box>
			{isLoggedIn && (
				<List
					sx={{
						justifyContent: "center",
						background: !isMobile ? "#fffb" : "",
						marginBottom: (theme) => theme.spacing(isMobile ? 1 : 3),
					}}
					className={!isMobile ? "content-background" : ""}
					onClick={onClick}
				>
					{/*<ListItemStyle to={"/"}>
				<UserLogo src={UserLogo} />
				<LinkStyle>Entre vous et nous</LinkStyle>
			</ListItemStyle>
			<ListItemStyle to={"/faq"} sx={{ marginBottom: (theme) => theme.spacing(isMobile ? 1 : 3) }}>
				<LifeBuoy />
				<LinkStyle>La FAQ</LinkStyle>
			</ListItemStyle>
			<ListItemStyle to={"/compte"}>
				<User />
				<LinkStyle>Mon compte</LinkStyle>
			</ListItemStyle>*/}
					<ListItemStyle to={"/profil"}>
						<CustomerCard width={20} height={20} />
						<LinkStyle>Mon profil</LinkStyle>
					</ListItemStyle>
					<ListItemStyle to={"/abonnement"}>
						<CheckCircle width={20} height={20} />
						<LinkStyle>Mes abonnements</LinkStyle>
					</ListItemStyle>
					<ListItemStyle to={"/don"}>
						<ForYou width={20} height={20} />
						<LinkStyle>Mes donations</LinkStyle>
					</ListItemStyle>
					{/*<ListItemStyle to={"/donate"}>
				<ForYou />
				<LinkStyle>Mes donations</LinkStyle>
			</ListItemStyle>*/}
					<ListItemStyle to={"/news"}>
						<MailOpen width={20} height={20} />
						<LinkStyle>Mes newsletters</LinkStyle>
					</ListItemStyle>
					<ListItemStyle to={"/commandes"}>
						<Order width={20} height={20} />
						<LinkStyle>Mes commandes</LinkStyle>
					</ListItemStyle>

					{/*<ListItemStyle to={"/favorites"}>
				<Heart />
				<LinkStyle>Mes favoris</LinkStyle>
			</ListItemStyle>*/}
					<ListItemStyle to={"/logout"} sx={{}} onClick={logOut}>
						<LogOut width={20} height={20} />
						<LinkStyle>Me déconnecter</LinkStyle>
					</ListItemStyle>
				</List>
			)}
			<List
				sx={{
					justifyContent: "center",
					background: !isMobile ? "#fffb" : "",
				}}
				className={!isMobile ? "content-background" : ""}
				onClick={onClick}
			>
				<ListItemStyle to={"/faq"}>
					<LifeBuoy width={20} height={20} />
					<LinkStyle>La FAQ</LinkStyle>
				</ListItemStyle>
				<ListItemStyle to={"/contact"}>
					<Mail width={20} height={20} />
					<LinkStyle>Contact</LinkStyle>
				</ListItemStyle>
				{!isLoggedIn && (
					<ListItemStyle to={"/logout"} sx={{}} onClick={login}>
						<User width={20} height={20} />
						<LinkStyle>Me connecter</LinkStyle>
					</ListItemStyle>
				)}
			</List>
		</Box>
	);
};

const NoMatch = () => {
	const navigate = useNavigate();
	const isLoggedIn = useIsLoggedIn();

	React.useEffect(() => {
		navigate(!!isLoggedIn ? "/profil" : "/faq");
	}, []);
	return null;
};

const RoutesDefinitions = () => {
	const isMobile = useIsMobile();

	const showSnackBar = useSelector(getShowSnackbar);
	const snackbarText = useSelector(getSnackbarText);
	const snackbarSeverity = useSelector(getSnackbarSeverity) || "success";

	const dispatch = useDispatch();
	const handleCloseSnackbar = () => {
		dispatch(setShowSnackbar(false));
	};

	// useLogUserIn();
	// useRedirectToLogin();
	return (
		<div style={{ position: "relative" }}>
			<BrowserRouter>
				<Header />
				<Grid container spacing={2} sx={{ paddingX: isMobile ? 2 : 3, flexWrap: "nowrap", marginBottom: 14 }}>
					{!isMobile && (
						<Grid
							item
							xs={3}
							lg={2}
							sx={{ minWidth: 250, maxWidth: 300, marginBottom: (theme) => theme.spacing(5) }}
						>
							<SideBar />
						</Grid>
					)}
					<Grid item xs lg={10} sx={{ marginBottom: (theme) => theme.spacing(4) }}>
						<Routes>
							{/*<Route path="/" element={<NoMatch />} />*/}
							<Route path="/" element={<Profil />} />
							<Route path="/profil" element={<Profil />} />
							<Route path="/abonnement" element={<Abonnement />} />
							<Route path="/news" element={<Newsletter />} />
							<Route path="/commandes" element={<Orders />} />
							<Route path="/commande" element={<SeeOrder />} />
							<Route path="/faq" element={<FAQ />} />
							<Route path="/don" element={<Donations />} />
							<Route exact path="/don-success/:montant" element={<DonationSuccess />} />
							<Route exact path="/don-echec/:montant" element={<DonationFailure />} />
							<Route path="/contact" element={<Contact />} />
							<Route path="*" element={<NoMatch />} />
						</Routes>
					</Grid>
				</Grid>
				<Footer />
			</BrowserRouter>
			<Snackbar
				open={showSnackBar}
				autoHideDuration={2000}
				onClose={handleCloseSnackbar}
				anchorOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
			>
				<Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: "100%" }}>
					{snackbarText}
				</Alert>
			</Snackbar>
		</div>
	);
};

export default RoutesDefinitions;
