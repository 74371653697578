import React, { useCallback, useMemo } from "react";
import {
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	InputAdornment,
	Typography,
} from "@mui/material";
import InexButton from "../InexButton";
import InexSelect from "../InexSelect";
import { useGetCreditCardsQuery, useGetSepaQuery } from "../../store/api/user";
import InexDatePicker from "../InexDatePicker";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { getInreesId } from "../../store/reducers/authSlice";
import InexNumberInput from "../InexNumberInput";
import { useUpdateDonationMutation } from "../../store/api/donation";
import DonationPaymentMethod from "./DonationPaymentMethod";
import { setShowSnackbar, setSnackbarSeverity, setSnackbarText } from "../../store/reducers/snackbarSlice";

const DONATION_FREQUENCY = [
	{ name: "Seulement cette fois", id: 0 },
	{ name: "Tous les mois", id: 1, label: "tous les mois" },
	{ name: "Tous les ans", id: 2, label: "tous les ans" },
];

const DonationModal = ({ open, onClose = () => {}, onValidate = () => {}, uniqueDonation = false, ...props }) => {
	const formRef = React.useRef(null);
	const inreesId = useSelector(getInreesId);

	const isCreationModal = React.useMemo(() => {
		return (typeof open === "boolean" && open === true) || uniqueDonation === true;
	}, [open, uniqueDonation]);

	const { data: creditCards } = useGetCreditCardsQuery({});
	const { data: sepa } = useGetSepaQuery({});

	const _paymentMethods = React.useMemo(() => {
		if (sepa && creditCards) {
			return sepa.concat(creditCards);
		}
	}, [sepa, creditCards]);

	const [donationValue, setDonationValue] = React.useState(1);
	const [currentFrequency, setCurrentFrequency] = React.useState(DONATION_FREQUENCY[1]);
	const [paymentDate, setPaymentDate] = React.useState(dayjs().add(1, "day"));
	const [selectedPaymentIndex, setSelectedPaymentIndex] = React.useState(null);

	React.useEffect(() => {
		if (open !== false && open != null && _paymentMethods.length > 0) {
			setDonationValue(isCreationModal ? 1 : open.don / 100);
			setCurrentFrequency(
				isCreationModal ? DONATION_FREQUENCY[1] : DONATION_FREQUENCY.find((e) => e.id === open.typedon)
			);
			setPaymentDate(isCreationModal ? dayjs().add(1, "day") : dayjs(open.daterenouv * 1000));
			setSelectedPaymentIndex(
				isCreationModal
					? null
					: _paymentMethods.findIndex((e) =>
							open.typerenouv === 2 ? e.idcb === open.cb : e.idsepa === open.iban
					  )
			);
		}
	}, [open, isCreationModal, _paymentMethods]);

	const [updateDonation, { isLoading }] = useUpdateDonationMutation();

	const _handleDonationValueChange = (value) => {
		const val = parseInt(value, 10) < 1 ? 1 : value;
		setDonationValue(parseInt(val, 10) < 1 ? 1 : val);
	};

	const _onFrequencyChange = (e) => {
		setCurrentFrequency(e.target.value);
	};

	const _onIndexUpdated = React.useCallback(
		(index) => {
			console.log(index);
			setSelectedPaymentIndex(_paymentMethods[index]);
			setSelectedPaymentIndex(index);
		},
		[_paymentMethods]
	);

	const canChangeFrequency = React.useMemo(() => {
		return creditCards?.length > 0 && uniqueDonation === false;
	}, [creditCards, uniqueDonation]);

	const shouldRedirectWithForm = React.useMemo(() => {
		return !canChangeFrequency || currentFrequency.id === 0;
	}, [canChangeFrequency, currentFrequency]);

	const _formIsValid = React.useMemo(() => {
		if (!_paymentMethods) return null;
		if (shouldRedirectWithForm) return donationValue > 0;
		const paymentMethod = _paymentMethods[selectedPaymentIndex];
		return (
			donationValue > 0 && [0, 1, 2].includes(currentFrequency.id) && paymentMethod != null && paymentDate != null
		);
	}, [donationValue, currentFrequency, paymentDate, selectedPaymentIndex, shouldRedirectWithForm]);

	const dispatch = useDispatch();
	const setShowSnackBar = (type = "success", message = "Vos informations ont bien été mises à jour.") => {
		dispatch(setShowSnackbar(true));
		dispatch(setSnackbarSeverity(type));
		dispatch(setSnackbarText(message));
	};

	// TODO : fetch addresses : if there is none, open the AddressCreationModal with a message : Veuillez créer une adresse pour la donation.

	// 2 cas :
	// 1 -	Le user n'a pas de carte de paiement enregistrée (il n'est pas abonné)
	// 		Dans ce cas on ne peux que choisir un montant et lorsque l'on clique sur le bouton "Payer" on redirige avec un form en POST vers la page de paiement
	// 2 -	Le user a une carte enregistrée (Il est donc abonné)
	// 		Dans ce cas, le user peut choisir une fréquence de paiement pour sa donation
	// 		1 - 	Il peut choisir de faire un don unique : dans ce cas on fait le même cas que le cas 1
	//		2 - 	Il peut choisir une récurrence de donation, donc on ne le redirige pas vers la page de paiement, mais on lui donne un affichage de succès de création de la donation.
	// A noter : Si la personne n'est pas abonnée, elle n'aura pas d'adresse sur son compte. s'il n'y a pas d'adresse, la personne doit donc ajouter une adresse.
	// On ouvre alors directement la modal pour créer une adresse.

	return (
		<Dialog open={!!open} onClose={onClose} fullWidth={true} maxWidth={"md"}>
			<DialogTitle color={"secondary"} fontFamily={"Branding Bold"}>
				{isCreationModal ? "Faire un don" : "Modifier ma donation"}
			</DialogTitle>
			<DialogContent
				sx={{
					minHeight: "30vh",
				}}
			>
				<Box sx={{ marginTop: 0, marginBottom: 2, position: "relative" }}>
					<Grid item container gap={2} xs={12} flexDirection={"column"}>
						<Grid item xs={12}>
							<InexNumberInput
								label={"Ma donation"}
								defaultValue={donationValue}
								value={donationValue}
								onChange={_handleDonationValueChange}
								formControlSx={{
									width: "100%",
								}}
								startAdornment={
									<InputAdornment
										position={"start"}
										sx={{
											marginTop: "17px",
										}}
									>
										<Box
											sx={{
												display: "flex",
												alignItems: "flex-end",
											}}
										>
											<Typography color={"secondary"}>€</Typography>
										</Box>
									</InputAdornment>
								}
							/>
						</Grid>

						{canChangeFrequency && (
							<>
								<Grid item xs={12}>
									<InexSelect
										sxContainer={{ backgroundColor: "white" }}
										value={currentFrequency}
										defaultValue={DONATION_FREQUENCY[1]}
										onChange={_onFrequencyChange}
										options={DONATION_FREQUENCY.filter((e, i) => {
											return !(isCreationModal === false && i === 0);
										})}
										label={"Fréquence de la donation"}
										renderOptions={(option) => (
											<Box sx={{}}>
												<Typography>{option.name}</Typography>
											</Box>
										)}
									/>
								</Grid>
								{currentFrequency && currentFrequency.id !== 0 && (
									<>
										<Grid item xs={12} flexWrap={"nowrap"}>
											<InexDatePicker
												value={paymentDate}
												onChange={(newValue) => setPaymentDate(newValue)}
												maxDate={dayjs().add(1, "year")}
												minDate={dayjs().add(1, "day")}
												sx={{ width: "100%" }}
											/>
											<Typography variant={"caption"} sx={{ marginLeft: 0.5 }}>
												{"Cette date correspond à la date de votre prélèvement " +
													currentFrequency.label +
													"."}
											</Typography>
										</Grid>
										<Grid container spacing={2} item xs={12} mt={1}>
											<Typography sx={{ marginLeft: 2, fontFamily: "Branding SemiBold" }}>
												{"Moyen de paiement"}
											</Typography>
											{_paymentMethods?.map((infos, index) => (
												<DonationPaymentMethod
													key={index}
													infos={infos}
													index={index}
													selectedIndex={selectedPaymentIndex}
													setSelectedIndex={_onIndexUpdated}
												/>
											))}
										</Grid>
									</>
								)}
							</>
						)}
					</Grid>
				</Box>
			</DialogContent>
			<DialogActions>
				<Grid container flexDirection={"row"} justifyContent={"flex-end"} columnSpacing={1}>
					<Grid item xs={6} md={2}>
						<InexButton
							onClick={() => {
								onClose();
							}}
							text={"Annuler"}
							sx={{
								width: "100%",
								height: "50px",
								"&:hover": {
									borderColor: (theme) => theme.palette.secondary.main,
									background: (theme) => theme.palette.grey[300],
								},
							}}
							textSx={{
								color: (theme) => theme.palette.secondary.main,
								textTransform: "none",
								"&:first-letter": {
									textTransform: "none",
								},
							}}
							textWithGradient={false}
							disableBackground={true}
						/>
					</Grid>
					<Grid item xs={6} md={2}>
						<InexButton
							text={shouldRedirectWithForm ? "Payer" : "Valider"}
							disabled={!_formIsValid}
							onClick={() => {
								if (shouldRedirectWithForm) {
									// Redirects to the payment page.
									formRef.current.submit();
								} else {
									const paymentMethod = _paymentMethods[selectedPaymentIndex];
									if (paymentMethod) {
										updateDonation({
											typerenouv: paymentMethod["idcb"] != null ? 2 : 3, // 2 = card / 3 = sepa
											daterenouv: dayjs(paymentDate).unix(),
											idcb: paymentMethod.idcb || 0,
											idiban: paymentMethod.idsepa || 0,
											montant: parseInt(donationValue, 10) * 100 || 0,
											typedon: currentFrequency?.id,
										}).then(() => {
											setShowSnackBar();
											onValidate?.();
										});
									} else {
										// TODO : show an error.
										setShowSnackBar("error", "Une erreur est survenue.");
									}
									onClose?.();
								}
							}}
							sx={{
								width: "100%",
								height: "50px",
							}}
						/>
						{shouldRedirectWithForm && (
							<form
								ref={(element) => {
									if (element) formRef.current = element;
								}}
								method="POST"
								action={"https://secure2.inrees.com/acmd_don.php"}
								style={{
									display: "none",
								}}
							>
								<input key={"Montant"} type="hidden" name={"montant"} value={donationValue * 100} />;
								<input key={"IdInexplore"} type="hidden" name={"idinexplore"} value={inreesId} />;
							</form>
						)}
					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>
	);
};

export default DonationModal;
